"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.displayStyles = exports.colorStyles = exports.weightStyles = void 0;

var _variables = require("../../../common/styles/variables");

var weightStyles = {
  light: {
    fontWeight: _variables.fontWeight.light
  },
  normal: {
    fontWeight: _variables.fontWeight.normal
  },
  medium: {
    fontWeight: _variables.fontWeight.medium
  },
  bold: {
    fontWeight: _variables.fontWeight.bold
  }
};
exports.weightStyles = weightStyles;
var colorStyles = {
  body: {
    color: _variables.colors.textDark
  },
  brand: {
    color: _variables.colors.textBrand
  },
  brandPrimary: {
    color: _variables.colors.brandPrimary
  },
  brandSecondary: {
    color: _variables.colors.brandSecondary
  },
  white: {
    color: _variables.colors.textWhite
  },
  dark: {
    color: _variables.colors.textDark
  },
  light: {
    color: _variables.colors.textLight
  },
  red: {
    color: _variables.colors.textRed
  }
};
exports.colorStyles = colorStyles;
var displayStyles = {
  body: {
    sm: {
      fontSize: "16px",
      lineHeight: "22px"
    },
    md: {
      fontSize: "18px",
      lineHeight: "26px"
    }
  },
  headline: {
    sm: {
      fontSize: "40px",
      lineHeight: "45px",
      fontWeight: "bold"
    },
    md: {
      fontSize: "60px",
      lineHeight: "69px"
    }
  },
  title: {
    sm: {
      fontSize: "32px",
      lineHeight: "35px"
    },
    md: {
      fontSize: "44px",
      lineHeight: "52px"
    }
  },
  titleSmall: {
    sm: {
      fontSize: "22px",
      lineHeight: "24px"
    },
    md: {
      fontSize: "25px",
      lineHeight: "34px"
    }
  },
  titleTiny: {
    sm: {
      fontSize: "16px",
      lineHeight: "26px"
    },
    md: {
      fontSize: "16px",
      lineHeight: "26px"
    }
  },
  meta: {
    sm: {
      fontSize: "16px",
      lineHeight: "18px"
    },
    md: {
      fontSize: "18px",
      lineHeight: "21px"
    }
  },
  metaSmall: {
    sm: {
      fontSize: "14px",
      lineHeight: "16px"
    },
    md: {
      fontSize: "16px",
      lineHeight: "20px"
    }
  },
  small: {
    sm: {
      fontSize: "14px",
      lineHeight: "22px"
    },
    md: {
      fontSize: "14px",
      lineHeight: "22px"
    }
  },
  tiny: {
    sm: {
      fontSize: "12px",
      lineHeight: "19px"
    },
    md: {
      fontSize: "12px",
      lineHeight: "19px"
    }
  }
};
exports.displayStyles = displayStyles;