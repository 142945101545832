import * as React from "react";
import { Link, withRouter } from "react-router-dom";
import { Header, Space, Text, spacing, Box, api } from "@pc/shared";

class HeaderContainer extends React.PureComponent {
  state = {
    listingNumber: ""
  };
  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      window.scrollTo(0, 0);
    }
  }
  searchProperties = e => {
    e.preventDefault();
    this.props.history.push(`/search`, {
      listingNumber: this.state.listingNumber
    });
  };

  render() {
    return (
      <Header
        as={Link}
        bgColor={this.props.bgColor}
        primaryMenuItems={api.header.primary}
        primaryCta={
          <Space margin top bottom size={spacing.space2}>
            <Box boxShadow borderRadius>
              <Text display="small" color="light">
                <div className="header-search">
                  <Space
                    inline
                    margin
                    top
                    right
                    bottom
                    left
                    size={spacing.space1}
                  >
                    <i className="icon icon-search-big" />
                  </Space>

                  <form onSubmit={this.searchProperties}>
                    <input
                      type="text"
                      placeholder="Search web reference"
                      onChange={e =>
                        this.setState({ listingNumber: e.target.value })
                      }
                      value={this.state.listingNumber}
                    />
                  </form>
                </div>
              </Text>
            </Box>
          </Space>
        }
      />
    );
  }
}

HeaderContainer.defaultProps = {
  bgColor: "white"
};

export default withRouter(HeaderContainer);
