"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = _interopRequireWildcard(require("styled-components"));

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {}; if (desc.get || desc.set) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  margin: 0;\n  padding: 0;\n  font-size: 0px;\n  flex: none;\n  width: ", ";\n  height: ", ";\n  /* width: auto; */\n  max-width: 100%;\n  max-height: 100%;\n  display: ", ";\n  border-radius: ", ";\n  background-image: url(", ");\n  background-position: center center;\n  background-size: cover;\n  background-repeat: no-repeat;\n  ", ";\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n    padding-bottom: ", ";\n  "]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var display = function display(props) {
  var displayStyles = {
    landscape: {
      paddingBottom: "60%"
    },
    portrait: {
      paddingBottom: "120%"
    },
    square: {
      paddingBottom: "100%"
    }
  };
  var style = displayStyles[props.display];
  return (0, _styledComponents.css)(_templateObject(), style.paddingBottom);
};

var StyledImage = _styledComponents.default.div(_templateObject2(), function (props) {
  return props.fluid ? "100%" : props.width;
}, function (props) {
  return props.fluid ? "auto" : "100%";
}, function (props) {
  return props.fixed ? "inline-block" : "block";
}, function (props) {
  return props.borderRadius;
}, function (props) {
  return props.src ? props.src : null;
}, display);

function Image(props) {
  var width = props.width,
      src = props.src,
      alt = props.alt,
      fixed = props.fixed,
      fluid = props.fluid,
      borderRadius = props.borderRadius,
      display = props.display;
  return _react.default.createElement(StyledImage, {
    borderRadius: borderRadius,
    fixed: fixed,
    src: src,
    alt: alt,
    fluid: fluid,
    display: display,
    width: width
  });
}

Image.defaultProps = {
  fixed: false,
  src: null,
  fluid: true,
  borderRadius: "0px",
  display: "landscape",
  width: null
};
Image.propTypes = {
  /** Alt text for the image */
  alt: _propTypes.default.string,

  /** Image src */
  src: _propTypes.default.string.isRequired,

  /** Is this image not fluid */
  fixed: _propTypes.default.bool,

  /** Is the Image responsive? */
  fluid: _propTypes.default.bool,

  /** Border Radius in css value */
  borderRadius: _propTypes.default.string,

  /** What is orientation of image? */
  display: _propTypes.default.oneOf(["portrait", "landscape", "square"]),

  /** If the width is fixed, define the value with px */
  width: _propTypes.default.string
};
var _default = Image;
exports.default = _default;