import * as React from "react";
import { Space, Text, InputCheckBox, spacing } from "@pc/shared";

class SelectCheckbox extends React.PureComponent {
  render() {
    const { label, left, value, onClick, checked } = this.props;

    let checkOptions = {};

    if (onClick) {
      checkOptions.onClick = () => onClick(value);
    }

    let check = <InputCheckBox {...checkOptions} checked={checked} />;

    return (
      <Space margin top bottom size={spacing.space1}>
        <div className="d-flex justify-content-between align-items-center">
          {left ? check : null}
          <Text inline display="small" weight="normal">
            {label}
          </Text>
          {!left ? check : null}
        </div>
      </Space>
    );
  }
}

SelectCheckbox.defaultProps = {
  value: null,
  left: false,
  label: null,
  onClick: null,
  checked: false
};

export default SelectCheckbox;
