"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PrimaryMenu = PrimaryMenu;

var React = _interopRequireWildcard(require("react"));

var _reactPose = _interopRequireDefault(require("react-pose"));

var _reactRouterDom = require("react-router-dom");

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _variables = require("../../../common/styles/variables");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {}; if (desc.get || desc.set) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } } newObj.default = obj; return newObj; } }

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  margin: 0;\n  padding: 0;\n  list-style: none;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: right;\n  margin-top: ", ";\n  @media (min-width: 992px) {\n    flex-direction: row;\n    margin-top: ", ";\n    margin-left: ", ";\n    margin-right: ", ";\n  }\n  li {\n    margin: 0;\n    margin-left: ", ";\n    margin-right: ", ";\n    font-family: ", ";\n    font-size: 16px;\n    line-height: 1;\n    font-weight: ", ";\n    display: flex;\n    a {\n      transition: color ", ";\n      text-decoration: none;\n      padding-top: ", ";\n      padding-bottom: ", ";\n      color: ", ";\n      :hover,\n      :focus,\n      :active {\n        color: ", ";\n        text-decoration: none;\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var Item = _reactPose.default.li({
  open: {
    y: 0,
    opacity: 1
  },
  closed: {
    y: -10,
    opacity: 0
  }
});

function PrimaryMenu(props) {
  var items = props.items,
      sticky = props.sticky,
      bgColor = props.bgColor,
      openStyle = props.openStyle;
  return React.createElement(StyledPrimaryMenu, {
    sticky: sticky,
    bgColor: bgColor,
    openStyle: openStyle
  }, items.map(function (p) {
    return React.createElement(Item, {
      key: p.label
    }, p.externalLink ? React.createElement("a", {
      href: p.url
    }, p.label) : React.createElement(_reactRouterDom.Link, {
      to: p.url,
      title: p.label
    }, p.label));
  }));
}

var StyledPrimaryMenu = _styledComponents.default.ul(_templateObject(), _variables.spacing.space2, _variables.spacing.space0, _variables.spacing.space2, _variables.spacing.space2, _variables.spacing.space2, _variables.spacing.space2, _variables.fonts.sans, _variables.fontWeight.medium, _variables.timing.base, _variables.spacing.space1, _variables.spacing.space1, function (props) {
  return props.bgColor === "white" || props.sticky === true || props.openStyle === true ? _variables.colors.textDark : _variables.colors.textWhite;
}, _variables.colors.textBrand);