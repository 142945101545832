import * as React from "react";
import { Formik, Form, Field } from "formik";
import { graphql, compose } from "react-apollo";
import { withRouter } from "react-router-dom";
import {
  Box,
  Button,
  BSInputComponent,
  BSCheckComponent,
  BSTextareaComponent,
  BSSelectComponent,
  Space,
  Text,
  spacing
} from "@pc/shared";
import { MUTATION_SUBMIT_FORM } from "../graphql/mutations";

class EnquiryForm extends React.Component {
  state = {
    message: null
  };
  getFieldByType(field, errors) {
    switch (field.type) {
      case "text":
      case "email":
      case "tel":
        return <Field {...field} component={BSInputComponent} />;
      case "radio":
      case "checkbox":
        return (
          <Field
            type={field.type}
            label={field.label}
            description={field.description}
            name={field.name}
            component={BSCheckComponent}
          />
        );
      case "textarea":
        return (
          <Field
            type={field.type}
            label={field.label}
            description={field.description}
            name={field.name}
            component={BSTextareaComponent}
          />
        );
      case "select":
        return (
          <Field
            type={field.type}
            label={field.label}
            description={field.description}
            name={field.name}
            component={BSSelectComponent}
          />
        );
      default:
        return null;
    }
  }

  render() {
    const { form, property, agent } = this.props;
    const { message } = this.state;
    return (
      <Formik
        onSubmit={async (values, { setSubmitting, setStatus, resetForm }) => {
          const { form } = this.props;

          setSubmitting(true);

          await this.props.submitForm({
            variables: {
              input: {
                fields: {
                  ...values
                },
                property: property ? property.mandateID : null,
                agent: agent,
                form: form.id
              }
            }
          });

          this.setState({
            message: "Successfully Sent"
          });
          setSubmitting(false);
        }}
        validate={values => {
          let errors = {};
          if (!values.email) {
            errors.email = "Required";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address";
          }
          return errors;
        }}
        render={({ errors, status, touched, isSubmitting }) => {
          return (
            <Form>
              {message && (
                <Box borderRadius backgroundColor="dark">
                  <Space padding top left bottom right size={spacing.space1}>
                    <Text>{message}</Text>
                  </Space>
                </Box>
              )}
              <div className="row">
                {form.fields.map(field => (
                  <div className={field.className} key={field.id}>
                    {this.getFieldByType(field, errors)}
                  </div>
                ))}
              </div>

              <Space top size={spacing.space3}>
                <div className="d-flex align-items-center justify-content-between">
                  <Button type="submit">
                    {!isSubmitting ? form.buttonText : "Submitting"}
                  </Button>
                </div>
              </Space>
            </Form>
          );
        }}
      />
    );
  }
}

export default compose(
  withRouter,
  graphql(MUTATION_SUBMIT_FORM, { name: "submitForm" })
)(EnquiryForm);
