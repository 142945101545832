import React from "react";
import { Space, Text, Box, Border, spacing } from "@pc/shared";
import { formatCurrency } from "../../../common/format";

export class BondAndTransfer extends React.Component {
  componentDidMount() {
    this.props.onCalculate();
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-4">
          <Space padding right bottom left size="20px">
            <Space bottom size={spacing.space2}>
              <Text display="small" weight="medium">
                Purchase Price
              </Text>
              <input
                className="form-control"
                type="number"
                name="purchasePrice"
                value={
                  this.props.data.purchasePrice
                    ? this.props.data.purchasePrice
                    : 0
                }
                onChange={event =>
                  this.props.onUpdateState(
                    event.target.name,
                    event.target.value
                  )
                }
              />
            </Space>
            <Space top size={spacing.space2}>
              <Text display="small" weight="medium">
                Current Bond Dept
              </Text>
              <input
                type="number"
                className="form-control"
                name="currentBondDept"
                value={this.props.data.currentBondDept}
                onChange={event =>
                  this.props.onUpdateState(
                    event.target.name,
                    event.target.value
                  )
                }
              />
            </Space>
          </Space>
        </div>

        <div className="col-md-8">
          <Box border borderRadius>
            <Space padding right left size={spacing.space4}>
              <Space top bottom size={spacing.space3}>
                <Space bottom size={spacing.space1}>
                  <div className="d-flex justify-content-between">
                    <Text display="titleTiny" color="brand">
                      Total Bond and Transfer Costs:
                    </Text>
                    <Text
                      display="titleTiny"
                      color="brand"
                      weight="medium"
                      html={formatCurrency(
                        this.props.data.bondAndTransferCostsTotal
                      )}
                    />
                  </div>
                </Space>
                <Text display="small">Costs are effective as of July 2018</Text>
              </Space>

              <Space top bottom size={spacing.space3}>
                <Space top bottom size={spacing.space1}>
                  <div className="d-flex justify-content-between">
                    <Text display="titleTiny" color="brand">
                      Bond registration cost breakdown:
                    </Text>
                    <Text
                      display="small"
                      html={formatCurrency(
                        this.props.data.bondRegistrationTotal
                      )}
                    />
                  </div>
                </Space>
                <Space bottom size={spacing.space1}>
                  <Border color="borderDark" />
                </Space>
                <Space top bottom size={spacing.space2}>
                  <div className="d-flex justify-content-between">
                    <Text display="small">
                      Bond registration cost (incl VAT)
                    </Text>
                    <Text
                      display="small"
                      html={formatCurrency(
                        this.props.data.bondRegistration_Cost
                      )}
                    />
                  </div>
                </Space>
                <Space top bottom size={spacing.space2}>
                  <div className="d-flex justify-content-between">
                    <Text display="small">
                      Bank initiation fee (incl. VAT)*
                    </Text>
                    <Text
                      display="small"
                      html={formatCurrency(
                        this.props.data.bondRegistration_InitiationFee
                      )}
                    />
                  </div>
                </Space>
                <Space top bottom size={spacing.space2}>
                  <div className="d-flex justify-content-between">
                    <Text display="small">Deeds office levy***</Text>
                    <Text
                      display="small"
                      html={formatCurrency(
                        this.props.data.bondRegistration_Levy
                      )}
                    />
                  </div>
                </Space>
                <Space top bottom size={spacing.space2}>
                  <div className="d-flex justify-content-between">
                    <Text display="small">
                      Postage, petties and other application fees (incl. VAT)
                    </Text>
                    <Text
                      display="small"
                      html={formatCurrency(
                        this.props.data.bondRegistration_Postage
                      )}
                    />
                  </div>
                </Space>

                <Space top bottom size={spacing.space3}>
                  <Space margin top bottom size={spacing.space1}>
                    <div className="d-flex justify-content-between">
                      <Text display="titleTiny" color="brand">
                        Property transfer cost breakdown:
                      </Text>
                      <Text
                        display="small"
                        html={formatCurrency(
                          this.props.data.propertyTransferTotal
                        )}
                      />
                    </div>
                  </Space>
                  <Space margin bottom size={spacing.space1}>
                    <Border color="borderDark" />
                  </Space>
                  <Space margin top bottom size={spacing.space2}>
                    <div className="d-flex justify-content-between">
                      <Text display="small">
                        Property transfer costs (incl. VAT)*
                      </Text>
                      <Text
                        display="small"
                        html={formatCurrency(
                          this.props.data.propertyTransfer_Cost
                        )}
                      />
                    </div>
                  </Space>
                  <Space margin top bottom size={spacing.space2}>
                    <div className="d-flex justify-content-between">
                      <Text display="small">Deeds office levy***</Text>
                      <Text
                        display="small"
                        html={formatCurrency(
                          this.props.data.propertyTransfer_Levy
                        )}
                      />
                    </div>
                  </Space>
                  <Space margin top bottom size={spacing.space2}>
                    <div className="d-flex justify-content-between">
                      <Text display="small">Transfer duty**</Text>
                      <Text
                        display="small"
                        html={formatCurrency(
                          this.props.data.propertyTransfer_Duty
                        )}
                      />
                    </div>
                  </Space>
                  <Space margin top bottom size={spacing.space2}>
                    <div className="d-flex justify-content-between">
                      <Text display="small">
                        Postage, petties and other application fees (incl. VAT)
                      </Text>
                      <Text
                        display="small"
                        html={formatCurrency(
                          this.props.data.propertyTransfer_Postage
                        )}
                      />
                    </div>
                  </Space>
                </Space>
              </Space>
            </Space>
          </Box>
          <Space margin top bottom size={spacing.space3}>
            <Text display="tiny" color="dark" weight="light" align="justify">
              * This is a once-off fee charged by the banks to open a loan
              account. This fee applies to natural persons.
            </Text>
          </Space>
          <Space margin top bottom size={spacing.space3}>
            <Text display="tiny" color="dark" weight="light" align="justify">
              ** This is a government tax on the transfer of property title.
            </Text>
          </Space>
          <Space margin top bottom size={spacing.space3}>
            <Text display="tiny" color="dark" weight="light" align="justify">
              *** Non VATable.
            </Text>
          </Space>
        </div>
      </div>
    );
  }
}
