import * as React from "react";
import styled from "styled-components";
import { Button, ButtonText, Space, spacing } from "@pc/shared";

class SelectBox extends React.Component {
  state = {
    open: false
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false);
  }

  handleClick = e => {
    if (this.node.contains(e.target)) {
      return;
    }

    this.setState({
      open: false
    });
  };

  getOption() {
    const { selected, plural, placeholder, renderLabel } = this.props;

    if (renderLabel) {
      return renderLabel();
    }

    if (selected.length > 0) {
      return `${plural}: ${selected.length}`;
    }

    if (selected.length === 0) {
      return `${placeholder}`;
    }

    return selected[0].label;
  }

  render() {
    const StyledOverlay = styled.div`
      position: absolute;
      top: 48px;
      left: 0px;
      right: 0px;
      z-index: 10;
      box-shadow: rgba(0, 0, 0, 0.15) 0px 14px 36px 2px;
      overflow-y: auto;
      overflow-x: hidden;
      white-space: normal;
      display: inline-block;
      max-height: calc(100vh - 168px);
      visibility: visible;
      background: rgb(255, 255, 255);
      border-width: 1px;
      border-style: solid;
      border-color: rgba(0, 0, 0, 0.2);
      border-image: initial;
      border-radius: 4px;
      padding: 16px 24px;
      min-width: 300px;
    `;

    return (
      <span className="mr-md-3 mb-3 mb-md-0">
        <div style={{ position: "relative" }} ref={node => (this.node = node)}>
          <Button
            display="outline"
            size="small"
            onClick={() => this.setState(state => ({ open: !state.open }))}
            block={true}
          >
            <span>{this.getOption()}</span>
          </Button>

          <div className="menu_box">
            {this.state.open ? (
              <StyledOverlay style={this.props.orderByBox && { left: "unset" }}>
                {this.props.children}

                <Space margin top size={spacing.space2}>
                  <div className="text-right">
                    <ButtonText
                      display="primary"
                      onClick={() => this.setState({ open: false })}
                    >
                      Close
                    </ButtonText>
                  </div>
                </Space>
              </StyledOverlay>
            ) : null}
          </div>
        </div>
      </span>
    );
  }
}

SelectBox.defaultProps = {
  selected: [],
  single: "Region",
  plural: "Regions",
  placeholder: "All Regions"
};

export default SelectBox;
