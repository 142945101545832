"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _variables = require("../../../common/styles/variables");

var _style = require("./style");

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {}; if (desc.get || desc.set) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  font-family: ", ";\n  text-align: ", ";\n  display: ", ";\n  white-space: ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n      overflow: hidden;\n      white-space: nowrap;\n      text-overflow: ellipsis;\n    "]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n    font-weight: ", ";\n  "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n    color: ", ";\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n    font-size: ", ";\n    line-height: ", ";\n    /* Medium Screens */\n    @media (min-width: 768px) {\n      font-size: ", ";\n      line-height: ", ";\n    }\n  "]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var defaultProps = {
  display: "body",
  color: "dark",
  weight: "normal"
};

var display = function display(props) {
  var style = _style.displayStyles[props.display];
  return (0, _styledComponents.css)(_templateObject(), style.sm.fontSize, style.sm.lineHeight, style.md.fontSize, style.md.lineHeight);
};

var color = function color(props) {
  var style = _style.colorStyles[props.color];
  return (0, _styledComponents.css)(_templateObject2(), style.color);
};

var weight = function weight(props) {
  var style = _style.weightStyles[props.weight];
  return (0, _styledComponents.css)(_templateObject3(), style.fontWeight);
};

var truncate = function truncate(props) {
  if (props.truncate) {
    return (0, _styledComponents.css)(_templateObject4());
  }
};

var Text = function Text(_ref) {
  var T = _ref.as,
      props = _objectWithoutProperties(_ref, ["as"]);

  return _react.default.createElement(T, props);
};

var StyledText = (0, _styledComponents.default)(Text)(_templateObject5(), _variables.fonts.sans, function (props) {
  return [props.align];
}, function (props) {
  return props.inline ? "inline-block" : "block";
}, function (props) {
  return props.noWrap && "nowrap";
}, display, color, truncate, weight);
var _default = StyledText;
exports.default = _default;
StyledText.defaultProps = defaultProps;