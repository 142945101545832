import React from "react";
import { HomePage } from "./ui/HomePage";
import { PAGE_QUERY } from "../../shared/queries/page";
import { HeaderMeta } from "../../shared/ui/Helmet";
import { graphql } from "react-apollo";
class HomeContainer extends React.Component {
  searchProperties = values => {
    this.props.history.push(`/search`, values);
  };
  render() {
    const { loading, viewPage: p } = this.props;
    if (loading) return <p>Loading</p>;

    return (
      <React.Fragment>
        <HeaderMeta seo={p.seo} />
        <HomePage onSearch={this.searchProperties} page={p} />
        {this.props.children}
      </React.Fragment>
    );
  }
}

export default graphql(PAGE_QUERY, {
  options: () => {
    return {
      variables: { slug: "home" }
    };
  },
  props: ({ data }) => ({ ...data })
})(HomeContainer);
