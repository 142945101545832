import hero from "../common/images/home_hero.png";
import home1 from "../common/images/home1.jpg";
import home2 from "../common/images/home2.jpg";
import home3 from "../common/images/home3.jpg";

export const homeContent = {
  heroImage: hero,
  benefitsTitle: "All under our roof",
  benefits: [
    {
      title: "Franchise",
      image: home1,
      description:
        "Become part of the team. Exciting opportunity to grow your personal real estate brand today.",
      buttonLabel: "Learn more"
    },
    {
      title: "Agents",
      image: home2,
      description:
        "Looking for a new home? Browse our agents in your area to help you find your next home.",
      buttonLabel: "Learn more"
    },
    {
      title: "Looking to Sell?",
      image: home3,
      description:
        "Let us assist you with the process. Get in contact with an agent or arrange a home valuation.",
      buttonLabel: "Learn more"
    }
  ]
};


export const icons = {
  'Bedroom': 'bed',
  'Bathroom': 'bath',
  'Garage': 'car',
}