import React from "react";
import { Query } from "react-apollo";
import { FranchisePage } from "./ui/FranchisePage";
import { PAGE_QUERY } from "../../shared/queries/page";
import { HeaderMeta } from "../../shared/ui/Helmet";
import { Loading } from "@pc/shared";

export class FranchiseContainer extends React.Component {
  state = {
    open: false
  }
  toggleModal = (state) => {
    this.setState({
      open: state
    })
  }
  render() {
    return (
      <Query query={PAGE_QUERY} variables={{ slug: "franchise" }}>
        {({ loading, error, data }) => {
          if (error) return <div>{error}</div>;
          if (loading)
            return (
              <div>
                <Loading />
              </div>
            );
          const { viewPage: p } = data;

          return (
            <React.Fragment>
              <HeaderMeta seo={p.seo} />
              <FranchisePage page={p} open={this.state.open} onToggleModal={this.toggleModal} />
            </React.Fragment>
          );
        }}
      </Query>
    );
  }
}
