"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _variables = require("../../common/styles/variables");

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {}; if (desc.get || desc.set) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  border-top: 1px solid transparent;\n  ", ";\n  margin: 0px;\n  padding: 0px;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n    border-color: ", ";\n    background-color: ", ";\n  "]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var colorStyles = {
  borderBase: {
    borderColor: _variables.colors.borderBase,
    backgroundColor: _variables.colors.borderBase
  },
  borderDark: {
    borderColor: _variables.colors.borderDark,
    backgroundColor: _variables.colors.borderDark
  },
  borderLight: {
    borderColor: _variables.colors.borderLight,
    backgroundColor: _variables.colors.borderLight
  },
  borderSecondary: {
    borderColor: _variables.colors.borderSecondary,
    backgroundColor: _variables.colors.borderSecondary
  },
  borderTransparent: {
    borderColor: _variables.colors.borderTransparent,
    backgroundColor: _variables.colors.borderTransparent
  }
};

var color = function color(props) {
  var style = colorStyles[props.color];
  return (0, _styledComponents.css)(_templateObject(), style.borderColor, style.borderColor);
};

var StyledBorder = _styledComponents.default.hr(_templateObject2(), color);

function Border(props) {
  var border = props.border,
      color = props.color;
  return _react.default.createElement(StyledBorder, {
    border: border,
    color: color
  });
}

Border.defaultProps = {
  border: true,
  color: "borderBase"
};
Border.propTypes = {
  /** Is there a border? */
  border: _propTypes.default.bool.isRequired,

  /** Color of the border? */
  color: _propTypes.default.oneOf(["borderBase", "borderDark", "borderLight", "borderSecondary", "borderTransparent"])
};
var _default = Border;
exports.default = _default;