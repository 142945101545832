import React from "react";
import {
  Space,
  Text,
  spacing,
  ButtonText,
  Image,
  Label,
  CardProperty,
  SliderWrapper
} from "@pc/shared";
import { Link } from "react-router-dom";
import { formatCurrency } from "../../../common/format";
import { icons } from "../../../common/content";

export const SoldListings = props => {
  const { agent, activeProps, soldProps } = props;

  return (
    <Space bottom top size={spacing.space15}>
      <Space bottom size={spacing.space8}>
        <div className="d-md-flex justify-content-between align-items-center">
          <Text display="title" color="brand" weight="medium">
            Sold listings <Label>{soldProps.length}</Label>
          </Text>
          <div className="d-flex">
            {activeProps ? (
              <div className="mr-3">
                <ButtonText
                  onClick={() => props.onToggleListings()}
                  iconAfter="angle-right"
                >
                  View current
                </ButtonText>
              </div>
            ) : null}

            <ButtonText
              onClick={() => props.onViewAll(agent)}
              iconAfter="angle-right"
            >
              View all
            </ButtonText>
          </div>
        </div>
      </Space>
      <div className="row">
        {soldProps.length ? (
          <React.Fragment>
            {soldProps.map(p => {
              return (
                <Space
                  bottom
                  size={spacing.space4}
                  className="col-sm-6 col-md-6 col-lg-3"
                >
                  <CardProperty
                    size="small"
                    display="vertical"
                    title={formatCurrency(p.price)}
                    meta={`${p.suburb.suburbName}, ${p.suburb.city}`}
                    renderVisual={
                      <SliderWrapper pager pagerType="arrow" lazyLoad={true}>
                        {p.images.map(image => (
                          <div key={image.id}>
                            <Image fluid src={image.imageServer} />
                          </div>
                        ))}
                      </SliderWrapper>
                    }
                    renderCta={hover => (
                      <ButtonText
                        as={Link}
                        to={`/property/${p.mandateID}`}
                        hover={hover}
                        inline
                        display="secondary"
                        iconAfter="angle-right"
                      >
                        View property
                      </ButtonText>
                    )}
                  >
                    {["Bedroom", "Bathroom", "Garage"].map(key =>
                      p.counts && p.counts[key] ? (
                        <Space
                          key={key}
                          inline
                          margin
                          right
                          size={spacing.space2}
                        >
                          <Text inline display="metaSmall">
                            <i className={`icon icon-${icons[key]}`} />{" "}
                            {p.counts[key]}
                          </Text>
                        </Space>
                      ) : null
                    )}
                  </CardProperty>
                </Space>
              );
            })}
          </React.Fragment>
        ) : (
          <Space
            bottom
            size={spacing.space4}
            className="col-sm-6 col-md-6 col-lg-3"
          >
            <Text display="small" color="brandPrimary" weight="medium">
              No sold properties
            </Text>
          </Space>
        )}
      </div>
    </Space>
  );
};
