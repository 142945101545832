import React from "react";
import Helmet from "react-helmet-async";

export const HeaderMeta = ({ seo }) => (
  <Helmet
    defaultTitle={seo.title !== "" ? seo.title : null}
    meta={[
      {
        name: "description",
        content: seo.description || seo["metadesc"]
      },
      {
        name: "og:title",
        content: seo.title || seo["opengraph-title"]
      },
      {
        name: "og:description",
        content: seo.description || seo["opengraph-description"]
      },
      { name: "og:url", content: seo.url || null },
      { name: "og:site_name", content: "Property Cloud" },
      { name: "og:type", content: `business` },
      {
        name: "og:image",
        content: seo.image ? seo.image : seo["opengraph-image"]
      },
      { name: "fb:app_id", content: "226095260788468" },
      { name: "twitter:card", content: "summary_large_image" },
      { name: "twitter:site", content: "https://propertycloud.co.za" },
      {
        name: "twitter:title",
        content: seo.title
      },
      {
        name: "twitter:description",
        content: seo.description
      },
      {
        name: "twitter:image:src",
        content: seo.image ? seo.image : seo["opengraph-image"]
      }
    ]}
    htmlAttributes={{ lang: "en" }}
  />
);
