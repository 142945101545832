import React from "react";
// import Rheostat from "rheostat";
// import "rheostat/css/rheostat.css";
import { Space, Text, Box, Border, spacing } from "@pc/shared";
import { formatCurrency } from "../../../common/format";

export class AdditionalPayment extends React.Component {
  constructor(props) {
    super(props);

    this.updateValue = this.updateValue.bind(this);
  }

  componentDidMount() {
    this.props.onCalculate();
  }

  updateValue(sliderState) {
    this.props.onUpdateState("loanTerm", sliderState.values);
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-4">
          <Space padding right bottom left size="20px">
            <Space bottom size={spacing.space2}>
              <Text display="small" weight="medium">
                Current Bond Dept
              </Text>
              <input
                className="form-control"
                type="number"
                name="currentBondDept"
                value={this.props.data.currentBondDept}
                onChange={event =>
                  this.props.onUpdateState(
                    event.target.name,
                    event.target.value
                  )
                }
              />
            </Space>
            <Space bottom top size={spacing.space2}>
              <Text display="small" weight="medium">
                Current Monthly Repayment
              </Text>
              <input
                type="number"
                className="form-control"
                name="monthlyRepayment"
                value={this.props.data.monthlyRepayment}
                onChange={event =>
                  this.props.onUpdateState(
                    event.target.name,
                    event.target.value
                  )
                }
              />
            </Space>
            <Space bottom top size={spacing.space2}>
              <Text display="small" weight="medium">
                Additional Monthly Repayment
              </Text>
              <input
                type="number"
                className="form-control"
                name="additionalMonthlyRepayment"
                value={this.props.data.additionalMonthlyRepayment}
                onChange={event =>
                  this.props.onUpdateState(
                    event.target.name,
                    event.target.value
                  )
                }
              />
            </Space>
            <Space bottom top size={spacing.space2}>
              <Text display="small" weight="medium">
                Interest Rate
              </Text>
              <input
                className="form-control"
                type="number"
                step="any"
                name="interestRate"
                value={this.props.data.interestRate}
                onChange={event =>
                  this.props.onUpdateState(
                    event.target.name,
                    event.target.value
                  )
                }
              />
            </Space>
            {/* <Space top size={spacing.space2}>
              {this.props.data.loanTerm > 0 ? (
                <React.Fragment>
                  <Text display="small" weight="medium">
                    Remaining Loan Term: {this.props.data.loanTerm} years
                  </Text>
                  <Rheostat
                    min="1"
                    max="30"
                    onValuesUpdated={this.updateValue}
                    values={[this.props.data.loanTerm]}
                  />
                </React.Fragment>
              ) : (
                <Box border borderRadius backgroundColor="light">
                  <Space top right bottom left padding size={spacing.space2}>
                    <Text display="small" weight="medium" color="red">
                      Monthly payment would not cover interest
                    </Text>
                  </Space>
                </Box>
              )}
            </Space> */}
          </Space>
        </div>

        <div className="col-md-8">
          <Box border borderRadius>
            <Space padding right left size={spacing.space4}>
              <Space top bottom size={spacing.space3}>
                <Space bottom size={spacing.space1}>
                  <div className="d-flex justify-content-between">
                    <Text display="titleTiny" color="brand">
                      You would save
                    </Text>
                    <Text
                      display="titleTiny"
                      color="brand"
                      weight="medium"
                      html={formatCurrency(this.props.data.amountSaved)}
                    />
                  </div>
                </Space>
              </Space>

              <Space top size={spacing.space3}>
                <Space top size={spacing.space1}>
                  {this.props.data.loanTerm > 0 ? (
                    <div className="d-flex justify-content-between">
                      <Text display="titleTiny" color="brand">
                        Current loan term:
                      </Text>
                      <div className="d-flex justify-content-between align-right">
                        <Space left size={spacing.space2}>
                          <Text
                            display="titleTiny"
                            inline={true}
                            color="brand"
                            weight="medium"
                            html={this.props.data.currentLoanTerm_Years.toString()}
                          />
                        </Space>
                        <Space left size={spacing.space2}>
                          <Text display="titleTiny" color="brand" inline={true}>
                            years
                          </Text>
                        </Space>
                        <Space left size={spacing.space2}>
                          <Text
                            display="titleTiny"
                            inline={true}
                            color="brand"
                            weight="medium"
                            html={this.props.data.currentLoanTerm_Months.toString()}
                          />
                        </Space>
                        <Space left size={spacing.space2}>
                          <Text display="titleTiny" color="brand" inline={true}>
                            months
                          </Text>
                        </Space>
                      </div>
                    </div>
                  ) : (
                    <Box border borderRadius backgroundColor="light">
                      <Space
                        top
                        right
                        bottom
                        left
                        padding
                        size={spacing.space2}
                      >
                        <Text display="small" weight="medium" color="red">
                          Monthly payment would not cover interest
                        </Text>
                      </Space>
                    </Box>
                  )}
                </Space>
              </Space>

              <Space top bottom size={spacing.space3}>
                <Space top bottom size={spacing.space1}>
                  <div className="d-flex justify-content-between">
                    <Text display="titleTiny" color="brand">
                      You would reduce your loan term to
                    </Text>
                    <div className="d-flex justify-content-between align-right">
                      <Space left bottom size={spacing.space2}>
                        <Text
                          display="titleTiny"
                          inline={true}
                          color="brand"
                          weight="medium"
                          html={this.props.data.reducedLoanTerm_Years.toString()}
                        />
                      </Space>
                      <Space left size={spacing.space2}>
                        <Text display="titleTiny" color="brand" inline={true}>
                          years
                        </Text>
                      </Space>
                      <Space left size={spacing.space2}>
                        <Text
                          display="titleTiny"
                          inline={true}
                          color="brand"
                          weight="medium"
                          html={this.props.data.reducedLoanTerm_Months.toString()}
                        />
                      </Space>
                      <Space left size={spacing.space2}>
                        <Text display="titleTiny" color="brand" inline={true}>
                          months
                        </Text>
                      </Space>
                    </div>
                  </div>
                </Space>
              </Space>
              <Space top bottom size={spacing.space3}>
                <Space top bottom size={spacing.space1}>
                  <div className="d-flex justify-content-between">
                    <Text display="small" color="brand">
                      Breakdown <strong>including</strong> additional monthly
                      repayment
                    </Text>
                  </div>
                </Space>
                <Space bottom size={spacing.space1}>
                  <Border color="borderDark" />
                </Space>
                <Space top bottom size={spacing.space2}>
                  <div className="d-flex justify-content-between">
                    <Text display="small">Monthly repayment</Text>
                    <Text
                      display="small"
                      html={formatCurrency(
                        this.props.data.monthlyRepayment_Including
                      )}
                    />
                  </div>
                </Space>
                <Space top bottom size={spacing.space2}>
                  <div className="d-flex justify-content-between">
                    <Text display="small">Total interest</Text>
                    <Text
                      display="small"
                      html={formatCurrency(
                        this.props.data.totalInterest_Including
                      )}
                    />
                  </div>
                </Space>
                <Space top bottom size={spacing.space2}>
                  <div className="d-flex justify-content-between">
                    <Text display="small">Total payment</Text>
                    <Text
                      display="small"
                      html={formatCurrency(
                        this.props.data.totalPayment_Including
                      )}
                    />
                  </div>
                </Space>
              </Space>

              <Space top bottom size={spacing.space3}>
                <Space top bottom size={spacing.space1}>
                  <div className="d-flex justify-content-between">
                    <Text display="small" color="brand">
                      Breakdown <strong>excluding</strong> additional monthly
                      repayment
                    </Text>
                  </div>
                </Space>
                <Space bottom size={spacing.space1}>
                  <Border color="borderDark" />
                </Space>
                <Space top bottom size={spacing.space2}>
                  <div className="d-flex justify-content-between">
                    <Text display="small">Monthly repayment</Text>
                    <Text
                      display="small"
                      html={formatCurrency(
                        this.props.data.monthlyRepayment_Excluding
                      )}
                    />
                  </div>
                </Space>
                <Space top bottom size={spacing.space2}>
                  <div className="d-flex justify-content-between">
                    <Text display="small">Total interest</Text>
                    <Text
                      display="small"
                      html={formatCurrency(
                        this.props.data.totalInterest_Excluding
                      )}
                    />
                  </div>
                </Space>
                <Space top bottom size={spacing.space2}>
                  <div className="d-flex justify-content-between">
                    <Text display="small">Total payment</Text>
                    <Text
                      display="small"
                      html={formatCurrency(
                        this.props.data.totalPayment_Excluding
                      )}
                    />
                  </div>
                </Space>
              </Space>
            </Space>
          </Box>
          <Space top bottom size={spacing.space3}>
            <Text display="tiny" color="dark" weight="light" align="justify" />
          </Space>
        </div>
      </div>
    );
  }
}
