import React from "react";
import PropTypes from "prop-types";
import Rheostat from "rheostat";
import "rheostat/css/rheostat.css";
import { formatCurrency } from "../../../common/format";
import { Text, Space, spacing } from "@pc/shared";
export default class SearchRheostate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      values: props.values || [0]
    };

    this.updateValue = this.updateValue.bind(this);
  }

  updateValue(sliderState) {
    this.setState({
      values: sliderState.values
    });
  }

  render() {
    const { formatValue, ...passProps } = this.props;
    const { values } = this.state;

    return (
      <div>
        <Rheostat
          {...passProps}
          onValuesUpdated={this.updateValue}
          values={values}
        />
        <Space top size={spacing.space2}>
          <Text inline display="small" weight="normal">
            {formatCurrency(values[0])} - {formatCurrency(values[1])}
          </Text>
        </Space>
      </div>
    );
  }
}
SearchRheostate.propTypes = {
  values: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  formatValue: PropTypes.func
};

SearchRheostate.defaultProps = {
  values: null,
  formatValue: null
};
