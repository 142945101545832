import gql from "graphql-tag";

export const PROPERTIES_QUERY = gql`
  query properties {
    properties {
      agents {
        agentId
        fullName
        mobile
        email
        image {
          url
        }
      }
      images {
        id
        imageServer
      }
      summary
      title
      created
      listingNumber
      price
      propertyType
      images {
        id
        imageServer
      }
      mandateID
      counts {
        Bedroom
        Bathroom
        Garage
      }
      suburb {
        id
        country
        postalCode
        province
        city
        suburbName
      }
    }
  }
`;

export const RELATED_PROPERTIES_QUERY = gql`
  query relatedProperties($suburbIds: [Int], $mandateId: Int, $agents: [Int]) {
    relatedProperties(
      input: { suburbIds: $suburbIds, mandateID: $mandateId, agentIds: $agents }
    ) {
      agents {
        agentId
        fullName
        mobile
        email
        image {
          url
        }
      }
      images {
        id
        imageServer
      }
      summary
      title
      listingNumber
      price
      propertyType
      images {
        id
        imageServer
      }
      mandateID
      counts {
        Bedroom
        Bathroom
        Garage
      }
      suburb {
        id
        country
        postalCode
        province
        city
        suburbName
      }
    }
  }
`;
