import gql from "graphql-tag";

export const PAGE_QUERY = gql`
  query viewPage($slug: String!) {
    viewPage(slug: $slug) {
      title
      content
      acf
      seo
    }
  }
`;
