"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.api = void 0;
var api = {
  header: {
    primary: [{
      label: "Franchise",
      url: "/franchise"
    }, {
      label: "Search",
      url: "/search"
    }, {
      label: "Agents",
      url: "/agents"
    }, {
      label: "Contact",
      url: "/contact"
    }]
  },
  footer: {
    primary: [{
      label: "Home",
      url: "/home"
    }, {
      label: "Franchise",
      url: "/franchise"
    }, {
      label: "Search",
      url: "/search"
    }, {
      label: "Agents",
      url: "/agents"
    }, {
      label: "Contact",
      url: "/contact"
    }, {
      label: "Login",
      url: "https://admin.propertycloud.co.za",
      externalLink: true
    }],
    social: [{
      label: "Facebook",
      url: "https://www.facebook.com/propertycloudsa/",
      icon: "facebook"
    }, {
      label: "Instagram",
      url: "https://www.instagram.com/propertycloudsa/",
      icon: "instagram"
    }, {
      label: "Twitter",
      url: "https://twitter.com/propertycloudsa",
      icon: "twitter"
    }],
    legal: [{
      label: "Terms and Conditions",
      url: "/terms-and-conditions"
    }, {
      label: "Privacy Policy",
      url: "/privacy-policy"
    }]
  }
};
exports.api = api;