"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _variables = require("../../../common/styles/variables");

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {}; if (desc.get || desc.set) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } } newObj.default = obj; return newObj; } }

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  border-style: ", ";\n  border-width: ", ";\n  border-radius: ", ";\n  border-color: ", ";\n  transition: all ", ";\n  height: ", ";\n  overflow: ", ";\n  ", ";\n  ", ";\n  ", ";\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n    box-shadow: ", ";\n    cursor: ", ";\n  "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n    background-image: url(", ");\n    background-size: ", ";\n    background-position: ", ";\n    background-repeat: ", ";\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n    background-color: ", ";\n  "]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var backgroundColor = function backgroundColor(props) {
  var backgroundStyles = {
    white: {
      backgroundColor: _variables.colors.bgWhite
    },
    light: {
      backgroundColor: _variables.colors.bgLight
    },
    dark: {
      backgroundColor: _variables.colors.bgDark
    }
  };
  var style = backgroundStyles[props.backgroundColor];
  return (0, _styledComponents.css)(_templateObject(), style.backgroundColor);
};

var backgroundImage = function backgroundImage(props) {
  return (0, _styledComponents.css)(_templateObject2(), props.backgroundImage, props.backgroundSize, props.backgroundPosition, props.backgroundRepeat);
};

var boxShadow = function boxShadow(props) {
  if (!props.boxShadow) return null;
  var boxShadowStyles = {
    base: {
      boxShadow: _variables.colors.borderShadow,
      cursor: "default"
    },
    withHover: {
      boxShadow: _variables.colors.borderTransparent,
      cursor: "default"
    },
    hover: {
      boxShadow: _variables.colors.borderShadow,
      cursor: "pointer"
    }
  };
  var style = boxShadowStyles.base;

  if (props.withHover) {
    style = boxShadowStyles.withHover;
  }

  if (props.hover) {
    style = boxShadowStyles.hover;
  }

  return (0, _styledComponents.css)(_templateObject3(), style.boxShadow, style.cursor);
};

var StyledBox = _styledComponents.default.div(_templateObject4(), function (props) {
  return props.border ? "solid" : "none";
}, function (props) {
  return props.border ? "1px" : "0px";
}, function (props) {
  return props.borderRadius && _variables.spacing.space05;
}, function (props) {
  return props.border && _variables.colors.borderBase;
}, _variables.timing.fast, function (props) {
  return props.height && props.height;
}, function (props) {
  return props.withHover ? "hidden" : "visible";
}, backgroundColor, boxShadow, backgroundImage);

var _default = StyledBox;
exports.default = _default;