"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _variables = require("../../common/styles/variables");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var marginStyle = function marginStyle(property, size, dimensions) {
  var forDimension = function forDimension(dimension) {
    return dimensions.includes(dimension) ? _variables.spacing[size] || size : "0";
  };

  return _defineProperty({}, property, [forDimension("top"), forDimension("right"), forDimension("bottom"), forDimension("left")].join(" "));
};
/**
 * Space component to add padding and margins.
 */


var Space = function Space(props) {
  var padding = props.padding,
      size = props.size,
      top = props.top,
      right = props.right,
      bottom = props.bottom,
      left = props.left,
      inline = props.inline,
      className = props.className,
      overrides = props.style,
      children = props.children,
      proxyProps = _objectWithoutProperties(props, ["padding", "size", "top", "right", "bottom", "left", "inline", "className", "style", "children"]);

  var property = padding ? "padding" : "margin";
  var dimensions = [top && "top", right && "right", bottom && "bottom", left && "left"].filter(Boolean);

  var style = _objectSpread({}, marginStyle(property, size, dimensions), overrides);

  if (inline) {
    return _react.default.createElement("span", _extends({
      style: style
    }, proxyProps, {
      className: className
    }), children);
  }

  return _react.default.createElement("div", _extends({
    style: style
  }, proxyProps, {
    className: className
  }), children);
};

Space.propTypes = {
  /** Will padding create the Space? */
  padding: _propTypes.default.bool,

  /** Will margin create the Space? */
  margin: _propTypes.default.bool,

  /** Size of the space - using spacing variables */
  size: _propTypes.default.string,

  /** Is the space at the top? */
  top: _propTypes.default.bool,

  /** Is the space at the right? */
  right: _propTypes.default.bool,

  /** Is the space at the bottom? */
  bottom: _propTypes.default.bool,

  /** Is the space at the left? */
  left: _propTypes.default.bool,

  /** Is the spacing inline? Wrap in a span, not a div */
  inline: _propTypes.default.bool,

  /** Style adds classes to divs - allowing Bootstrap classes to be added to wrapping divs (only if needed) */
  className: _propTypes.default.string,

  /** Style adds style overrides to divs - (only if needed) */
  style: _propTypes.default.object,

  /** Contents of Space*/
  children: _propTypes.default.any
};
Space.defaultProps = {
  padding: false,
  size: "default",
  top: false,
  right: false,
  bottom: false,
  left: false,
  inline: false,
  className: null,
  style: {},
  children: null
};
var _default = Space;
exports.default = _default;