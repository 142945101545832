import * as React from "react";
import { FormContainer } from "../../Form/FormContainer";
import EnquiryForm from "../../Form/ui/EnquiryForm";
import { Text, Space, spacing } from "@pc/shared";

export const Page = props => {
  const { page } = props;

  return (
    <div class="container">
      <div className="row">
        <div className="col-12">
          <Space top bottom size={spacing.space4}>
            <Text
              display="title"
              color="brand"
              weight="medium"
              html={page.title}
            />
          </Space>
        </div>
      </div>
      <Space bottom size={spacing.space15}>
        <div className="row">
          {page.acf.map ? (
            <React.Fragment>
              <div className="col-md-6">
                <div className="entry">
                  <Text display="body" weight="light" html={page.content} />
                </div>
              </div>
              <div className="col-md-6">
                <FormContainer
                  formId={"join"}
                  render={form => {
                    return <EnquiryForm form={form} />;
                  }}
                />
              </div>
            </React.Fragment>
          ) : (
            <div className="col-md-8">
              <div className="entry">
                <Text display="body" weight="light" html={page.content} />
              </div>
            </div>
          )}
        </div>
      </Space>
    </div>
  );
};
