import React, { Fragment } from "react";
import { hydrate } from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { ApolloProvider } from "react-apollo";
import { HelmetProvider } from "react-helmet-async";
import App from "./App";

import client from "./shared/apollo";

hydrate(
  <HelmetProvider>
    <ApolloProvider client={client}>
      <Fragment>
        <Router>
          <App />
        </Router>
      </Fragment>
    </ApolloProvider></HelmetProvider>,
  document.getElementById("root")
);

if (module.hot) {
  module.hot.accept();
}
