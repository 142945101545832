import React from "react";
import { Link } from "react-router-dom";
import { Border, Space, Text, ButtonAction, spacing } from "@pc/shared";
import gql from "graphql-tag";
import { StyledBreadcrumbs } from "../../Property/ui/Breadcrumbs";
const fragments = {
  fields: gql`
    fragment AreaHeaderInfo on Area {
      name
      description
      breadcrumbs {
        label
        slug
      }
    }
  `
};

export const SuburbHeader = ({ area, children }) => {
  return (
    <Space bottom size={spacing.space4}>
      {children}
      <div className="container">
        <div className="d-lg-flex justify-content-between align-items-end">
          <div>
            <StyledBreadcrumbs>
              <Space inline margin right size={spacing.space05}>
                {area.breadcrumbs &&
                  area.breadcrumbs.map(b => (
                    <li>
                      <Text
                        inline
                        display="small"
                        as={Link}
                        key={b.slug}
                        to={`${b.slug}`}
                      >
                        {b.label}
                      </Text>
                    </li>
                  ))}
              </Space>
            </StyledBreadcrumbs>

            <Text display="titleSmall" color="brand" weight="medium">
              Real Estate &amp; Property for sale in {area.name}
            </Text>
          </div>

          <div style={{ width: "180px" }} className={"mt-2 mt-lg-0"}>
            <ButtonAction display="primary" iconAfter="caret-down">
              Latest
            </ButtonAction>
          </div>
        </div>
        <Space bottom top size={spacing.space4}>
          <Border />
        </Space>
      </div>
    </Space>
  );
};

SuburbHeader.fragments = fragments;
