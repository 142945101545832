import React from "react";
import Rheostat from "rheostat";
import "rheostat/css/rheostat.css";
import { Space, Text, Box, ButtonText, Border, spacing } from "@pc/shared";
import { formatCurrency } from "../../../common/format";

export class MortgageBond extends React.Component {
  constructor(props) {
    super(props);

    this.updateValue = this.updateValue.bind(this);
  }

  componentDidMount() {
    this.props.onCalculate();
  }

  updateValue(sliderState) {
    this.props.onUpdateState("loanTerm", sliderState.values);
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-4">
            <Space padding right bottom left size="20px">
              <Space bottom size={spacing.space2}>
                <Text display="small" weight="medium">
                  Purchase Price
                </Text>
                <input
                  className="form-control"
                  type="number"
                  name="purchasePrice"
                  value={
                    this.props.data.purchasePrice
                      ? this.props.data.purchasePrice
                      : null
                  }
                  onChange={event =>
                    this.props.onUpdateState(
                      event.target.name,
                      event.target.value
                    )
                  }
                />
              </Space>
              <Space bottom top size={spacing.space2}>
                <Text display="small" weight="medium">
                  Deposit Amount
                </Text>
                <input
                  type="number"
                  className="form-control"
                  name="depositAmount"
                  value={this.props.data.depositAmount}
                  onChange={event =>
                    this.props.onUpdateState(
                      event.target.name,
                      event.target.value
                    )
                  }
                />
              </Space>
              <Space bottom top size={spacing.space2}>
                <Text display="small" weight="medium">
                  Interest Rate
                </Text>
                <input
                  className="form-control"
                  type="number"
                  step="any"
                  name="interestRate"
                  value={this.props.data.interestRate}
                  onChange={event =>
                    this.props.onUpdateState(
                      event.target.name,
                      event.target.value
                    )
                  }
                />
              </Space>
              <Space top size={spacing.space2}>
                <Text display="small" weight="medium">
                  Loan Term: {this.props.data.loanTerm} years
                </Text>
                <Rheostat
                  min="1"
                  max="30"
                  onValuesUpdated={this.updateValue}
                  values={[this.props.data.loanTerm]}
                />
              </Space>
            </Space>
            <Space padding right bottom left size="20px">
              <Space bottom size={spacing.space2}>
                {/* <Space inline right size={spacing.space1}>
                  <ButtonText
                    display='primary'
                    onClick={e => this.props.onCalculate(e)}
                  >
                    Calculate
                  </ButtonText>
                </Space> */}
                <Space inline right size={spacing.space1}>
                  <ButtonText
                    display="primary"
                    onClick={e => this.props.onClearValues(e)}
                  >
                    Clear All
                  </ButtonText>
                </Space>
              </Space>
            </Space>
          </div>

          <div className="col-md-8">
            <Box border borderRadius>
              <Space padding right left size={spacing.space4}>
                <Space top bottom size={spacing.space3}>
                  <Space bottom size={spacing.space1}>
                    <div className="d-flex justify-content-between">
                      <Text display="titleTiny" color="brand">
                        Monthly Repayment:
                      </Text>
                      <Text
                        display="titleTiny"
                        color="brand"
                        weight="medium"
                        html={formatCurrency(this.props.data.monthlyRepayment)}
                      />
                    </div>
                  </Space>
                  <Space bottom size={spacing.space1}>
                    <Border color="borderDark" />
                  </Space>
                  <ButtonText
                    size="small"
                    iconAfter="angle-right"
                    onClick={e => this.props.onSwitchTab(e, 2)}
                  >
                    Want to calculate additional bond repayments?
                  </ButtonText>
                </Space>

                <Space top bottom size={spacing.space3}>
                  <Space top bottom size={spacing.space1}>
                    <div className="d-flex justify-content-between">
                      <Text display="titleTiny" color="brand">
                        Total Once-off Costs:
                      </Text>
                      <Text
                        display="titleTiny"
                        color="brand"
                        weight="medium"
                        html={formatCurrency(
                          this.props.data.bondAndTransferCostsTotal
                        )}
                      />
                    </div>
                  </Space>
                  <Space bottom size={spacing.space1}>
                    <Border color="borderDark" />
                  </Space>
                  <Space top bottom size={spacing.space2}>
                    <div className="d-flex justify-content-between">
                      <Text display="small">Deposit</Text>
                      <Text
                        display="small"
                        html={
                          this.props.data.depositAmount
                            ? formatCurrency(this.props.data.depositAmount)
                            : "R 0"
                        }
                      />
                    </div>
                  </Space>
                  <Space top bottom size={spacing.space2}>
                    <div className="d-flex justify-content-between">
                      <Text display="small">
                        Bond registration costs (incl. VAT)
                      </Text>
                      <Text
                        display="small"
                        html={formatCurrency(
                          this.props.data.bondRegistrationTotal
                        )}
                      />
                    </div>
                  </Space>
                  <Space top bottom size={spacing.space2}>
                    <div className="d-flex justify-content-between">
                      <Text display="small">
                        Property transfer costs (incl. VAT)
                      </Text>
                      <Text
                        display="small"
                        html={formatCurrency(
                          this.props.data.propertyTransferTotal
                        )}
                      />
                    </div>
                  </Space>
                  <ButtonText
                    size="small"
                    iconAfter="angle-right"
                    onClick={e => this.props.onSwitchTab(e, 3)}
                  >
                    View detailed breakdown
                  </ButtonText>
                </Space>

                <Space top bottom size={spacing.space3}>
                  <Space top bottom size={spacing.space1}>
                    <div className="d-flex justify-content-between">
                      <Text display="titleTiny" color="brand">
                        Minimum gross monthly income required:
                      </Text>
                      <Text
                        display="titleTiny"
                        color="brand"
                        weight="medium"
                        html={formatCurrency(
                          this.props.data.grossMonthlyIncome
                        )}
                      />
                    </div>
                  </Space>
                  <Space bottom size={spacing.space1}>
                    <Border color="borderDark" />
                  </Space>
                  <ButtonText
                    size="small"
                    iconAfter="question"
                    onClick={e => this.props.onSwitchTab(e, 1)}
                  >
                    What do I qualify for based on my income?
                  </ButtonText>
                </Space>
              </Space>
            </Box>
          </div>
        </div>
        <div className="row">
          <Space left right top bottom size={spacing.space3}>
            <Text display="tiny" color="dark" weight="light" align="justify">
              Disclaimer: Please note that by default this calculator uses the
              prime interest rate for bond payment calculations. This is purely
              for convenience and not an indication of the interest rate that
              might be offered to you by a bank. This calculator is intended to
              provide estimates based on the indicated amounts, rates and fees.
              Whilst we make every effort to ensure the accuracy of these
              calculations, we cannot be held liable for inaccuracies.
              Property24 does not accept liability for any damages arising from
              the use of this calculator.
            </Text>
          </Space>
        </div>
      </div>
    );
  }
}
