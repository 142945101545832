import React from "react";
import { graphql } from "react-apollo";
import { AGENT_QUERY } from "./queries/agent";
import { Agent } from "./ui/Agent";
import { Loading } from "@pc/shared";
import { HeaderMeta } from "../../shared/ui/Helmet";
import { getImages } from "../../common/options";

class AgentContainer extends React.Component {
  state = {
    open: false,
    toggleCurrentListings: true
  };

  toggleModal = state => {
    this.setState({
      open: state
    });
  };

  toggleCurrentListings = () => {
    this.setState({
      toggleCurrentListings: !this.state.toggleCurrentListings
    });
  };

  viewAll = agent => {
    this.props.history.push("/search", { agents: [agent] });
  };

  render() {
    const { loading, agent, error } = this.props;
    if (loading) return <Loading />;
    if (error) return <div className="container">{error}</div>;

    if (!agent) return null;
    var seo = {
      title:
        agent.website && agent.website.title
          ? `${agent.website.title} - Property Cloud`
          : `Real Estate Agent ${agent.fullName} - Property Cloud`,
      metadesc:
        agent.website && agent.website.metaDescription
          ? agent.website.metaDescription
          : `Profile of ${agent.fullName}. View ${
              agent.fullName
            }'s real estate for sale, rentals, and sold properties`,
      image: agent.image ? getImages(agent.image.FileId, 300, 300) : null
    };

    return (
      <React.Fragment>
        <HeaderMeta seo={seo} />
        <Agent
          agent={agent}
          open={this.state.open}
          onViewAll={this.viewAll}
          onToggleModal={this.toggleModal}
          onToggleListings={this.toggleCurrentListings}
          showListing={this.state.toggleCurrentListings}
        />
      </React.Fragment>
    );
  }
}

export default graphql(AGENT_QUERY, {
  options: props => {
    const query = {};

    if (props.url) {
      query.site = props.url;
    } else {
      query.agentId = parseInt(props.match.params.id, 10);
    }

    return {
      variables: query
    };
  },
  props: ({ data }) => ({ ...data })
})(AgentContainer);
