import * as React from "react";
import { Hero, Space, Box, Card, Text, ButtonText, spacing } from "@pc/shared";
import { SearchContainer } from "../../Search/SearchContainer";

export const HomePage = props => {
  const { page, onSearch } = props;

  return (
    <React.Fragment>
      <Hero image={page.acf.hero.hero_image}>
        <div className="container">
          <div className="row">
            <div className="col-lg-10">
              <Space padding top bottom size={spacing.space10}>
                <Box borderRadius>
                  <Space padding top right bottom left size={spacing.space4}>
                    <Space margin size={spacing.space4}>
                      <Text
                        display="title"
                        color="brand"
                        weight="medium"
                        html={page.acf.hero.hero_headline}
                      />
                    </Space>
                    <SearchContainer
                      searchOnly={true}
                      saleType={"buttons"}
                      onSearch={onSearch}
                    />
                  </Space>
                </Box>
              </Space>
            </div>
          </div>
        </div>
      </Hero>
      <div className="container">
        <Space top bottom size={spacing.space10}>
          <div className="row">
            <div className="col-12 text-center">
              <Space bottom size={spacing.space8}>
                <Text
                  align="center"
                  display="title"
                  color="brand"
                  weight="medium"
                  html={page.acf.service.title}
                />
              </Space>
            </div>
          </div>
          <div className="row justify-content-around">
            {page.acf.service.services.map((card, index) => {
              return (
                <div className="col-md-3 col-sm-6 text-center">
                  <Card
                    index={index}
                    image={card.image}
                    title={card.title}
                    renderCta={
                      card.link_options.link_label ? (
                        <ButtonText
                          as="a"
                          href={card.link_options.link_url}
                          display="secondary"
                          iconAfter="angle-right"
                        >
                          {card.link_options.link_label}
                        </ButtonText>
                      ) : null
                    }
                  >
                    <Text
                      align="center"
                      display="body"
                      weight="light"
                      html={card.description}
                    />
                  </Card>
                </div>
              );
            })}
          </div>
        </Space>
      </div>
    </React.Fragment>
  );
};
