"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.displayStyles = void 0;

var _variables = require("../../../common/styles/variables");

var displayStyles = {
  base: {
    cursor: "pointer",
    color: _variables.colors.textBrand,
    border: _variables.colors.borderBase,
    background: _variables.colors.bgLight,
    // For CSS hover
    colorHover: "white",
    bgColorHover: _variables.colors.btnSecondary,
    borderColorHover: _variables.colors.btnSecondary,
    hover: {
      color: "white",
      background: _variables.colors.btnSecondary,
      border: _variables.colors.btnSecondary
    },
    checked: {
      color: _variables.colors.btnSecondary,
      background: "white",
      border: _variables.colors.btnSecondary
    },
    disabled: {
      opacity: "0.5",
      cursor: "not-allowed",
      color: "white",
      border: _variables.colors.btnSecondary
    }
  }
};
exports.displayStyles = displayStyles;