import React from "react";

import {
  ContentModal,
  Space,
  Text,
  spacing,
  Box,
  CardProfile,
  Button,
  ButtonText,
  Image,
  SliderWrapper
} from "@pc/shared";
import profile from "../../../common/images/profile.jpg";
import { ContentAgent } from "./ContentAgent";
import { getImages } from "../../../common/options";
import { CurrentListings } from "./CurrentListings";
import { SoldListings } from "./SoldListings";

const isWidgetValid = widget => {
  return widget && widget.title;
};

const showListing = props => {
  const {
    agent: { properties, soldProperties },
    showListing
  } = props;

  const activeProps = properties.filter(p => p.status !== "Sold");
  const soldProps = soldProperties.filter(p => p.featured);

  if (activeProps.length && soldProps.length) {
    return (
      <React.Fragment>
        {showListing ? (
          <CurrentListings {...props} soldProps={soldProps.length} />
        ) : (
          <SoldListings
            {...props}
            activeProps={activeProps.length}
            soldProps={soldProps}
          />
        )}
      </React.Fragment>
    );
  }

  if (activeProps.length) {
    return <CurrentListings {...props} soldProps={soldProps.length} />;
  }

  if (soldProps.length) {
    return (
      <SoldListings
        {...props}
        activeProps={activeProps.length}
        soldProps={soldProps}
      />
    );
  }

  return null;
};

export const Agent = props => {
  const { agent } = props;

  let testimonials = [];
  let preferredContacts = [];

  let widget = {};

  if (agent.testimonials) {
    testimonials = agent.testimonials;
  }

  if (agent.preferredContacts) {
    preferredContacts = agent.preferredContacts;
  }

  if (agent.widget) {
    widget = agent.widget;
  }

  return (
    <React.Fragment>
      <div className="container">
        <Space bottom top size={spacing.space4}>
          <div className="row">
            <div className="col-lg-8">
              <Box borderRadius backgroundColor="dark" height="100%">
                <Space padding top bottom left right size={spacing.space8}>
                  <Space bottom size={spacing.space4}>
                    <div className="row">
                      <div className="col-sm-3 d-block d-lg-none">
                        <Image
                          borderRadius={"50%"}
                          display="square"
                          src={
                            agent.image
                              ? getImages(agent.image.FileId, 350, 420)
                              : null
                          }
                        />
                      </div>
                      <div className="col-sm-9 col-lg-12">
                        <Text
                          display="title"
                          color="brandPrimary"
                          weight="medium"
                          html={agent.fullName}
                        />
                      </div>
                      <div className="col-12">
                        <Space bottom size={spacing.space2}>
                          <Text
                            display="meta"
                            color="brandPrimary"
                            html={agent.subtitle}
                          />
                        </Space>
                        <Space inline margin right size={spacing.space4}>
                          <ButtonText
                            iconBefore="envelope"
                            display="primary"
                            inline
                            as="a"
                            href={`mailto:${agent.email}`}
                          >
                            {agent.email}
                          </ButtonText>
                        </Space>

                        <Space inline margin right size={spacing.space4}>
                          <ButtonText
                            iconBefore="phone"
                            display="primary"
                            inline
                            as="a"
                            href={`tel:${agent.mobile}`}
                          >
                            {agent.mobile}
                          </ButtonText>
                        </Space>
                      </div>
                    </div>
                  </Space>

                  <Space bottom size={spacing.space4}>
                    <Text
                      color="body"
                      as="div"
                      weight="light"
                      // html={agent.about.replace(
                      //   new RegExp("<p><br></p>", "g"),
                      //   "<br/>"
                      // )}
                      html={
                        agent.about
                          ? agent.about.replace(
                              new RegExp("<p><br></p>", "g"),
                              "<br/>"
                            )
                          : null
                      }
                    />
                  </Space>
                  <div className="row mb-4">
                    <div className="col-sm-6 col-md-4">
                      <Space inline margin right size={spacing.space4}>
                        <Button onClick={() => props.onToggleModal(true)}>
                          Get in touch
                        </Button>
                      </Space>
                    </div>
                    <div className="col-sm-6 col-md-8">
                      {agent.social ? (
                        <React.Fragment>
                          {agent.social.facebook && (
                            <Space inline margin right size={spacing.space4}>
                              <ButtonText
                                as="a"
                                href={agent.social.facebook}
                                target="_blank"
                                rel="noopener noreferrer"
                                inline
                                display="primary"
                                iconBefore="facebook"
                              >
                                Facebook
                              </ButtonText>
                            </Space>
                          )}
                          {agent.social.instagram && (
                            <Space inline margin right size={spacing.space4}>
                              <ButtonText
                                as="a"
                                href={agent.social.instagram}
                                target="_blank"
                                rel="noopener noreferrer"
                                inline
                                display="primary"
                                iconBefore="instagram"
                              >
                                Instagram
                              </ButtonText>
                            </Space>
                          )}
                          {agent.social.twitter && (
                            <Space inline margin right size={spacing.space4}>
                              <ButtonText
                                as="a"
                                href={agent.social.twitter}
                                target="_blank"
                                rel="noopener noreferrer"
                                inline
                                display="primary"
                                iconBefore="twitter"
                              >
                                Twitter
                              </ButtonText>
                            </Space>
                          )}
                          {agent.social.youtube && (
                            <Space inline margin right size={spacing.space4}>
                              <ButtonText
                                inline
                                as="a"
                                display="primary"
                                iconBefore="youtube"
                                target="_blank"
                                href={agent.social.youtube}
                              >
                                YouTube
                              </ButtonText>
                            </Space>
                          )}
                          {agent.social.linkedin && (
                            <Space inline margin right size={spacing.space4}>
                              <ButtonText
                                inline
                                as="a"
                                display="primary"
                                iconBefore="linkedin"
                                target="_blank"
                                href={agent.social.linkedin}
                              >
                                LinkedIn
                              </ButtonText>
                            </Space>
                          )}
                          {agent.social.whatsapp && (
                            <Space inline margin right size={spacing.space4}>
                              <ButtonText
                                inline
                                as="a"
                                display="primary"
                                iconBefore="whatsapp"
                                target="_blank"
                                href={`https://api.whatsapp.com/send?phone=${
                                  agent.social.whatsapp
                                }&text=&source=&data=`}
                              >
                                WhatsApp
                              </ButtonText>
                            </Space>
                          )}
                        </React.Fragment>
                      ) : null}
                    </div>
                  </div>
                </Space>
              </Box>
            </div>
            <div className="col-lg-4 d-none d-lg-block">
              <Image
                src={agent.image ? agent.image.url : profile}
                alt="Profile image"
                borderRadius={spacing.space05}
                display="portrait"
              />
            </div>
          </div>
        </Space>
        {showListing(props)}
        {testimonials.length > 0 || isWidgetValid(widget) ? (
          <Space margin bottom top size={spacing.space15}>
            <Box borderRadius border>
              <div className="row align-items-center align-content-stretch">
                <React.Fragment>
                  {testimonials.length > 0 ? (
                    <div
                      className={`${
                        isWidgetValid(widget) ? "col-sm-6" : "col"
                      }  testimonials`}
                    >
                      <SliderWrapper dots lazyLoad={true} adaptiveHeight={true}>
                        {testimonials.map(e => {
                          return (
                            <Box backgroundColor="light">
                              <Space
                                padding
                                bottom
                                top
                                left
                                right
                                size={spacing.space8}
                              >
                                <Space margin bottom top size={spacing.space4}>
                                  <Text
                                    display="titleSmall"
                                    color="brand"
                                    weight="light"
                                    as="blockquote"
                                  >
                                    {`${e.testimonial}`}
                                  </Text>
                                </Space>
                                <Space margin bottom size={spacing.space4}>
                                  <Text
                                    display="meta"
                                    color="brand"
                                    weight="bold"
                                  >
                                    {e.from}
                                  </Text>
                                </Space>
                              </Space>
                            </Box>
                          );
                        })}
                      </SliderWrapper>
                    </div>
                  ) : null}

                  {isWidgetValid(widget) ? (
                    <div
                      className={testimonials.length > 0 ? "col-sm-6" : "col"}
                    >
                      <Space padding top bottom right size={spacing.space4}>
                        <div className="text-center">
                          <Space margin bottom size={spacing.space2}>
                            <Text
                              display="titleSmall"
                              color="brand"
                              weight="medium"
                              align="center"
                            >
                              {agent.widget.title}
                            </Text>
                          </Space>
                          <Space margin bottom size={spacing.space4}>
                            <Text
                              display="body"
                              as="p"
                              weight="light"
                              align="center"
                            >
                              {agent.widget.description}
                            </Text>
                          </Space>
                          {agent.widget.buttonLink ? (
                            <a
                              href={agent.widget.buttonLink}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Button color="primary" align="center">
                                {agent.widget.buttonText}
                              </Button>
                            </a>
                          ) : null}
                        </div>
                      </Space>
                    </div>
                  ) : null}
                </React.Fragment>
              </div>
            </Box>
          </Space>
        ) : null}

        {preferredContacts.length > 0 ? (
          <Space bottom top size={spacing.space15}>
            <React.Fragment>
              <div className="row">
                <div className="col-12">
                  <Space bottom size={spacing.space6}>
                    <Text display="title" color="brand" weight="medium">
                      {`${agent.firstName}’s Preferred Contacts`}
                    </Text>
                  </Space>
                </div>
              </div>

              <div className="row">
                {preferredContacts.map(e => {
                  return (
                    <div className="col-md-4">
                      <Space bottom size={spacing.space3}>
                        <CardProfile
                          image={null}
                          icon={e.icon}
                          title={e.title}
                          role={e.name}
                          renderCta={
                            e.url ? (
                              <Button
                                as="a"
                                display="outline"
                                size="small"
                                href={e.url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Visit website
                              </Button>
                            ) : null
                          }
                        >
                          {e.email ? (
                            <ButtonText
                              size="small"
                              iconBefore="envelope"
                              block
                            >
                              {e.email}
                            </ButtonText>
                          ) : null}
                          {e.telephone ? (
                            <ButtonText size="small" iconBefore="phone" block>
                              {e.telephone}
                            </ButtonText>
                          ) : null}
                        </CardProfile>
                      </Space>
                    </div>
                  );
                })}
              </div>
            </React.Fragment>
          </Space>
        ) : null}
      </div>
      <Box backgroundColor="light">
        <Space padding top bottom size={spacing.space15}>
          <div className="container">
            <div className="row">
              <div className="col-md-8 m-auto">
                <ContentAgent showSocial={true} {...agent} />
              </div>
            </div>
          </div>
        </Space>
      </Box>
      <ContentModal
        isOpen={props.open}
        closeModal={() => props.onToggleModal(false)}
        data={agent}
        render={agent => <ContentAgent showSocial={true} {...agent} />}
      />
    </React.Fragment>
  );
};
