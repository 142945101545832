"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AreaMenu = AreaMenu;

var React = _interopRequireWildcard(require("react"));

var _reactRouterDom = require("react-router-dom");

var _Text = require("../../Text/Text");

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _variables = require("../../../common/styles/variables");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {}; if (desc.get || desc.set) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } } newObj.default = obj; return newObj; } }

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  border-top: 1px solid ", ";\n  display: flex;\n  padding-top: ", ";\n  padding-bottom: ", ";\n  .area {\n    margin-bottom: ", ";\n    @media (min-width: 768px) {\n      margin-bottom: ", ";\n    }\n    h6 {\n      margin-bottom: ", ";\n    }\n    ul {\n      margin: 0;\n      padding: 0;\n      list-style: none;\n      li {\n        margin: 0;\n        a {\n          text-decoration: none;\n          :hover,\n          :focus,\n          :active {\n            color: ", ";\n            text-decoration: underline;\n          }\n        }\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

function AreaMenu(props) {
  var items = props.items;
  return React.createElement(StyledAreaMenu, {
    className: "d-print-none"
  }, React.createElement("div", {
    className: "row"
  }, items.map(function (a) {
    return React.createElement("div", {
      className: "col-6 col-md"
    }, React.createElement("div", {
      className: "area"
    }, React.createElement(_Text.Text, {
      as: "h6",
      display: "titleTiny",
      weight: "medium"
    }, "Property for sale in ", a.city), React.createElement("ul", null, a.suburbs.map(function (s) {
      return React.createElement("li", {
        key: s.label
      }, React.createElement(_Text.Text, {
        as: _reactRouterDom.Link,
        to: "/location".concat(s.slug),
        display: "small",
        html: s.name
      }));
    }))));
  })));
}

var StyledAreaMenu = _styledComponents.default.div(_templateObject(), _variables.colors.borderLight, _variables.spacing.space6, _variables.spacing.space6, _variables.spacing.space4, _variables.spacing.space0, _variables.spacing.space1, _variables.colors.brandSecondary);