import gql from "graphql-tag";

export const AGENT_QUERY = gql`
  query agent($agentId: Int, $site: String) {
    agent(input: { agentId: $agentId, site: $site }) {
      agentId
      fullName
      firstName
      lastName
      email
      work
      mobile
      propertyCount
      website {
        title
        metaDescription
      }
      social {
        facebook
        twitter
        instagram
        youtube
        linkedin
        whatsapp
      }
      testimonials {
        testimonial
        from
      }
      widget {
        title
        description
        buttonText
        buttonLink
      }
      preferredContacts {
        title
        name
        email
        telephone
        url
        icon
      }
      image {
        FileId
        url
      }
      properties(limit: 8) {
        mandateID
        created
        price
        title
        status
        suburb {
          suburbName
          city
        }
        images {
          imageServer
        }
        counts {
          Bedroom
          Bathroom
          Garage
        }
      }
      soldProperties {
        mandateID
        created
        price
        title
        featured
        suburb {
          suburbName
          city
        }
        images {
          imageServer
        }
        counts {
          Bedroom
          Bathroom
          Garage
        }
      }
      subtitle
      about
    }
  }
`;
