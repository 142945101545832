import React from "react";
import { Query } from "react-apollo";
import { Page } from "./ui/Page";
import { PAGE_QUERY } from "../../shared/queries/page";
import { HeaderMeta } from "../../shared/ui/Helmet";
import { Loading } from "@pc/shared";
import { Redirect } from "react-router-dom";
export class PageContainer extends React.Component {
  render() {
    return (
      <Query
        query={PAGE_QUERY}
        variables={{ slug: this.props.match.params.slug }}
      >
        {({ loading, error, data }) => {
          if (error) return <div>{error}</div>;
          if (loading)
            return (
              <div>
                <Loading />
              </div>
            );

          const { viewPage: p } = data;

          if (!p) return <Redirect to={"/search"} />;

          return (
            <React.Fragment>
              <HeaderMeta seo={p.seo} />
              <Page page={p} />
            </React.Fragment>
          );
        }}
      </Query>
    );
  }
}
