"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.media = exports.spacing = exports.opacity = exports.preferredContactIcons = exports.icons = exports.colors = exports.tracking = exports.fontWeight = exports.timing = exports.fonts = void 0;
var fonts = {
  sans: "'faricy-new-web', 'Helvetica Neue', Helvetica, Arial, sans-serif"
};
exports.fonts = fonts;
var timing = {
  fast: ".25s",
  slow: ".5s",
  slower: ".75s"
};
exports.timing = timing;
var fontWeight = {
  light: "300",
  normal: "400",
  medium: "500",
  bold: "700"
};
exports.fontWeight = fontWeight;
var tracking = {
  none: "0",
  small: "1px",
  medium: "1.5px"
};
exports.tracking = tracking;
var colors = {
  baseWhite: "#FFFFFF",
  baseBlack: "#000000",
  baseInherit: "inherit",
  baseTransparent: "transparent",
  brandBase: "#093660",
  brandPrimary: "#028595",
  brandSecondary: "#03C8E0",
  textBrand: "#093660",
  textWhite: "#FFFFFF",
  textDark: "#808B98",
  textLight: "#C5CCD3",
  textRed: "#E80606",
  btnBrand: "#093660",
  btnBrandHover: "#062038",
  btnPrimary: "#028595",
  btnPrimaryHover: "#00606C",
  btnSecondary: "#03C8E0",
  btnSecondaryHover: "#03A1B4",
  btnAlternative: "#ffffff",
  btnAlternativeHover: "#093660",
  borderBase: "#D7DCE2",
  borderLight: "rgba(215,220,226,0.50)",
  borderDark: "#093560",
  borderSecondary: "#03C8E0",
  borderShadow: "0 0 15px 2px rgba(0,0,0,0.1)",
  borderTransparent: "transparent",
  bgLight: "#F3F5F7",
  bgDark: "rgba(2,133,149,0.15)",
  bgBlack: "#000000",
  bgWhite: "#FFFFFF",
  bgTransparent: "rgba(255,255,255,0)",
  statusDanger: "#B0153C",
  statusInfo: "#85CEFF",
  statusNeutral: "#333333",
  statusSuccess: "#52A74B",
  statusWarning: "#F5A623"
}; // Icons from Fort Awesome
// https://fortawesome.com/kits/e844e434

exports.colors = colors;
var icons = {
  searchBig: "search-big",
  download: "download",
  question: "question",
  plus: "plus",
  minus: "minus",
  caretLeft: "caret-left",
  caretRight: "caret-right",
  caretDown: "caret-down",
  caretUp: "caret-up",
  bed: "bed",
  search: "search",
  angleLeft: "angle-left",
  angleRight: "angle-right",
  close: "close",
  check: "check",
  share: "share",
  print: "print",
  phone: "phone",
  envelopeFar: "envelope-far",
  envelope: "envelope",
  calendar: "calendar",
  home: "home",
  chart: "chart",
  money: "money",
  pen: "pen",
  paperclip: "paperclip",
  law: "law",
  // Social
  instagram: "instagram",
  twitter: "twitter",
  facebook: "facebook",
  googleplus: "googleplus",
  linkedin: "linkedin",
  youtube: "youtube",
  whatsapp: "whatsapp",
  key: "key",
  music: "music",
  building: "building",
  checkCircle: "check-circle",
  mobile: "mobile",
  userFemale: "user-female",
  globe: "globe",
  userMale: "user-male",
  shoppingCart: "shopping-cart",
  edit: "edit",
  bookOpen: "book-open",
  camera: "camera",
  gift: "gift",
  wrench: "wrench",
  briefcase: "briefcase",
  megaphone: "megaphone",
  smile: "smile",
  laptop: "laptop",
  heart: "heart",
  trophy: "trophy",
  pieChart: "pie-chart",
  mapMarker: "map-marker",
  lock: "lock"
};
exports.icons = icons;
var preferredContactIcons = {
  key: "key",
  music: "music",
  building: "building",
  checkCircle: "check-circle",
  mobile: "mobile",
  userFemale: "user-female",
  globe: "globe",
  userMale: "user-male",
  shoppingCart: "shopping-cart",
  edit: "edit",
  bookOpen: "book-open",
  camera: "camera",
  gift: "gift",
  wrench: "wrench",
  briefcase: "briefcase",
  megaphone: "megaphone",
  smile: "smile",
  laptop: "laptop",
  heart: "heart",
  trophy: "trophy",
  pieChart: "pie-chart",
  mapMarker: "map-marker",
  lock: "lock",
  law: "law"
};
exports.preferredContactIcons = preferredContactIcons;
var opacity = {
  solid: "1",
  hover: ".75",
  transparent: "0"
};
exports.opacity = opacity;
var spacingFactor = 8;
var spacing = {
  space0: "".concat(spacingFactor * 0, "px"),
  // 0
  space05: "".concat(spacingFactor / 2, "px"),
  // 4
  space1: "".concat(spacingFactor * 1, "px"),
  // 8
  space2: "".concat(spacingFactor * 2, "px"),
  // 16
  space3: "".concat(spacingFactor * 3, "px"),
  // 24
  space4: "".concat(spacingFactor * 4, "px"),
  // 32
  space5: "".concat(spacingFactor * 5, "px"),
  // 40
  space6: "".concat(spacingFactor * 6, "px"),
  // 48
  space7: "".concat(spacingFactor * 7, "px"),
  // 56
  space8: "".concat(spacingFactor * 8, "px"),
  // 64
  space9: "".concat(spacingFactor * 9, "px"),
  // 72
  space10: "".concat(spacingFactor * 10, "px"),
  // 80
  space11: "".concat(spacingFactor * 11, "px"),
  // 88
  space12: "".concat(spacingFactor * 12, "px"),
  // 96
  space13: "".concat(spacingFactor * 13, "px"),
  // 104
  space14: "".concat(spacingFactor * 14, "px"),
  // 168
  space15: "".concat(spacingFactor * 15, "px") // 120

};
exports.spacing = spacing;
var media = {
  min: {
    1430: "1430px",
    1410: "1410px",
    1350: "1350px",
    1290: "1290px",
    1200: "1200px",
    1080: "1080px",
    1024: "1024px",
    960: "960px",
    840: "840px",
    768: "768px",
    720: "720px",
    600: "600px",
    560: "560px",
    480: "480px",
    360: "360px",
    320: "320px"
  },
  max: {
    1530: "1530px",
    1430: "1430px",
    1410: "1410px",
    1350: "1350px",
    1290: "1290px",
    1200: "1200px",
    1080: "1080px",
    1024: "1024px",
    960: "960px",
    840: "840px",
    768: "768px",
    720: "720px",
    600: "600px",
    560: "560px",
    480: "480px",
    360: "360px",
    320: "320px"
  }
};
exports.media = media;