import "rheostat/initialize";
import React, { Fragment } from "react";
import Route from "react-router-dom/Route";
import Switch from "react-router-dom/Switch";
import "./App.css";
import HomeContainer from "./modules/Home/HomeContainer";
import HeaderContainer from "./modules/Navigation/HeaderContainer";
import { FooterContainer } from "./modules/Navigation/FooterContainer";
import { PropertyContainer } from "./modules/Property/PropertyContainer";
import { SearchContainer } from "./modules/Search/SearchContainer";
import { AgentsContainer } from "./modules/Agents/AgentsContainer";
import AgentContainer from "./modules/Agent/AgentContainer";
import { FranchiseContainer } from "./modules/Franchise/FranchiseContainer";
import { PageContainer } from "./modules/Page/PageContainer";
import SuburbContainer from "./modules/Suburb/SuburbContainer";

const App = props => (
  <Fragment>
    <Switch>
      <Route
        exact
        path="/"
        render={routeProps => {
          let url;
          if (process.browser) {
            url = window.location.host;
          } else {
            url = props.url;
          }

          if (process.env.RAZZLE_MAIN_URLS.split(",").includes(url)) {
            return (
              <React.Fragment>
                <HeaderContainer bgColor={"transparent"} />
                <HomeContainer {...routeProps} />
              </React.Fragment>
            );
          } else {
            return (
              <React.Fragment>
                <HeaderContainer />
                <AgentContainer url={url} {...routeProps} />
              </React.Fragment>
            );
          }
        }}
      />
      <Route
        exact
        path="/franchise"
        render={routeProps => (
          <React.Fragment>
            <HeaderContainer bgColor={"transparent"} />
            <FranchiseContainer {...routeProps} />
          </React.Fragment>
        )}
      />
      <Route
        exact
        path="/search"
        render={routeProps => (
          <React.Fragment>
            <HeaderContainer bgColor={"white"} />
            <SearchContainer {...routeProps} />
          </React.Fragment>
        )}
      />
      <Route
        exact
        path="/property/:propertyId"
        render={routeProps => (
          <React.Fragment>
            <HeaderContainer bgColor={"white"} />
            <PropertyContainer
              url={process.browser ? window.location.host : props.url}
              {...routeProps}
            />
          </React.Fragment>
        )}
      />
      <Route
        exact
        path="/agents"
        render={routeProps => (
          <React.Fragment>
            <HeaderContainer bgColor={"white"} />
            <AgentsContainer {...routeProps} />
          </React.Fragment>
        )}
      />
      <Route
        exact
        path="/agents/:id"
        render={routeProps => (
          <React.Fragment>
            <HeaderContainer bgColor={"white"} />
            <AgentContainer {...routeProps} />
          </React.Fragment>
        )}
      />
      <Route
        exact
        path="/location/:province/:city/:suburb"
        render={routeProps => (
          <SuburbContainer {...routeProps} type={"suburb"} />
        )}
      />
      <Route
        exact
        path="/location/:province/:city/"
        render={routeProps => <SuburbContainer {...routeProps} type={"city"} />}
      />
      <Route
        exact
        path="/location/:province/"
        render={routeProps => (
          <SuburbContainer {...routeProps} type={"province"} />
        )}
      />
      <Route
        exact
        path="/:slug"
        render={routeProps => (
          <React.Fragment>
            <HeaderContainer bgColor={"white"} />
            <PageContainer {...routeProps} />
          </React.Fragment>
        )}
      />
    </Switch>
    <FooterContainer />
  </Fragment>
);

export default App;
