import React from "react";
import { Tabs, Text, Tab, Space, Box, spacing } from "@pc/shared";
import ContentBlock from "./ContentBlock";
import MapBlock from "./MapBlock";
import gql from "graphql-tag";

const fragments = {
  fields: gql`
    fragment TabFields on Tabs {
      tabs {
        label
        tab {
          ...ContentFields
          ...MapFields
        }
      }
    }
    ${ContentBlock.fragments.fields}
    ${MapBlock.fragments.fields}
  `
};

class PropertyTabs extends React.Component {
  constructor() {
    super();

    this.state = {
      tab: 0
    };
  }

  render() {
    const { tabs } = this.props;

    if (!tabs) return null;

    const { tab } = tabs[this.state.tab];

    return (
      <React.Fragment>
        <Tabs>
          {tabs.map((tab, index) => (
            <Tab
              active={index === this.state.tab}
              onClick={() => this.setState({ tab: index })}
            >
              {tab.label}
            </Tab>
          ))}
        </Tabs>
        <div className="row">
          {tab
            .filter(
              t =>
                t.description ||
                (t.tags && t.tags.length) ||
                t.__typename === "MapBlock"
            )
            .map(t => (
              <React.Fragment>
                {t.__typename === "ContentBlock" ? (
                  <div className="col-md-6">
                    <ContentBlock {...t} />
                  </div>
                ) : null}

                {t.__typename === "MapBlock" ? (
                  <div className="col-md-12">
                    <MapBlock {...t} />
                  </div>
                ) : null}
              </React.Fragment>
            ))}
          {tab.filter(
            t =>
              t.description ||
              (t.tags && t.tags.length) ||
              t.__typename === "MapBlock"
          ).length === 0 ? (
            <div className="col-md-12">
              <Box borderRadius backgroundColor="dark">
                <Space padding top bottom size={spacing.space2}>
                  <Text display="meta" align="center" color="brandPrimary">
                    No Room Details
                  </Text>
                </Space>
              </Box>
            </div>
          ) : null}
        </div>
      </React.Fragment>
    );
  }
}

PropertyTabs.fragments = fragments;

export default PropertyTabs;
