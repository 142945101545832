import React from "react";
import { Query } from "react-apollo";
import { SearchResults } from "./ui/SearchResults";
import { HeaderMeta } from "../../shared/ui/Helmet";

import { SEARCH_QUERY } from "./queries/search";
import { SearchBox } from "./ui/SearchBox";
import { SearchHeader } from "./ui/SearchHeader";

export class SearchContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    let initState = {};

    if (this.props.location) {
      const { state } = this.props.location;
      initState = { ...state };
    }

    if (this.props.initState) {
      initState = {
        ...this.props.initState
      };
    }

    this.state = {
      mandateType: "Sale",
      locations: [],
      agents: [],
      types: [],
      bedrooms: 0,
      bathrooms: 0,
      garages: 0,
      limit: this.props.limit || 10,
      page: this.props.page || 1,
      priceRange: [0, 10000000],
      endPrice: null,
      orderBy: null,
      status: "Active",
      ...initState
    };
  }

  componentDidUpdate(prevProps) {
    /*
        if (prevProps.location.state) {
          const { state: { listingNumber } } = prevProps.location;
          const { state: { listingNumber: ln } } = this.props.location;
    
          if (ln !== listingNumber) {

            this.setState({
              listingNumber: ln
            })
          }
        }*/
  }

  formatUrlParamas = params => {
    const query = this.parseQueryString(params);

    return query;
  };

  parseQueryString = string => {
    return string
      .slice(1)
      .split("&")
      .map(queryParam => {
        let kvp = queryParam.split("=");
        return { key: kvp[0], value: kvp[1] };
      })
      .reduce((query, kvp) => {
        query[kvp.key] = kvp.value;
        return query;
      }, {});
  };

  resetState = () => {
    this.setState({
      locations: [],
      priceRange: [0, 10000000],
      types: [],
      agents: [],
      bedrooms: 0,
      bathrooms: 0,
      garages: 0,
      limit: this.props.limit || 10,
      page: 1,
      listingNumber: null,
      startPrice: null,
      endPrice: null
    });
  };

  roomsUpdate = (key, delta) => {
    const next = (a, b) => a + b;

    this.setState(state => ({
      [key]: next(state[key], delta) > 0 ? next(state[key], delta) : 0,
      page: 1
    }));
  };

  propertyTypeUpdate = type => {
    const updateArr = (arr, action) => {
      if (arr.indexOf(type) > -1) {
        return arr.filter(a => a !== action);
      } else {
        return [...arr, action];
      }
    };

    this.setState(state => ({
      types: updateArr(state.types, type),
      page: 1
    }));
  };

  orderBY = orderBy => {
    this.setState({
      orderBy: orderBy
    });
  };

  stateUpdate = (key, value) => {
    this.setState({
      [key]: value,
      page: 1
    });
  };

  searchProperties = () => {
    const state = { ...this.state };
    const input = {};

    if (state.mandateType) {
      input.mandateType = state.mandateType;
    }

    if (state.priceRange.length) {
      const [startPrice, endPrice] = state.priceRange;

      if (startPrice !== 0 || endPrice !== 10000000) {
        input.priceRange = {
          startPrice,
          endPrice
        };
      }
    }

    if (state.bedrooms > 0) {
      input.bedrooms = state.bedrooms;
    }

    if (state.bathrooms > 0) {
      input.bathrooms = state.bathrooms;
    }

    if (state.garages > 0) {
      input.garages = state.garages;
    }

    if (state.locations.length) {
      const locationQuery = {
        city: [],
        province: [],
        suburb: []
      };
      state.locations.forEach(l => {
        locationQuery[l.type] = [...locationQuery[l.type], l.value];
      });

      if (locationQuery.city.length) {
        input.city = locationQuery.city;
      }

      if (locationQuery.province.length) {
        input.province = locationQuery.province;
      }

      if (locationQuery.suburb.length) {
        input.suburbIds = locationQuery.suburb.map(s => parseInt(s, 10));
      }
    }

    if (state.types.length) {
      input.propertyType = state.types[0];
    }

    if (state.listingNumber) {
      input.listingNumber = state.listingNumber;
    }

    if (state.agents) {
      input.agentIds = state.agents.map(a => a.agentId);
    }

    if (state.orderBy) {
      input.orderBy = state.orderBy;
    }

    input.status = state.status;

    return {
      input,
      limit: state.limit,
      page: state.page
    };
  };

  searchQuery = () => {
    return this.state;
  };

  nextPage = () => {
    this.setState(
      state => ({
        page: state.page + 1
      }),
      () => window.scrollTo(0, 0)
    );
  };

  prevPage = () => {
    this.setState(
      state => ({
        page: state.page - 1 === 0 ? 1 : state.page - 1
      }),
      () => window.scrollTo(0, 0)
    );
  };

  render() {
    var seo = {
      title: "Property Cloud Real Estate Listings"
    };

    if (this.props.searchOnly) {
      return (
        <SearchBox
          agents={this.props.agents}
          container={this.props.container}
          onStateUpdate={this.stateUpdate}
          onSearch={() => this.props.onSearch(this.searchQuery())}
          onResetState={this.resetState}
          onRoomUpdate={this.roomsUpdate}
          onPropertyUpdate={this.propertyTypeUpdate}
          onLocationUpdate={value => this.setState({ locations: value })}
          search={this.state}
          saleType={this.props.saleType}
          searchPlaceholder={this.props.searchPlaceholder}
        />
      );
    }

    return (
      <Query query={SEARCH_QUERY} variables={this.searchProperties()}>
        {({ loading, error, data }) => {
          if (error) return <div>{error}</div>;

          const { properties, propertiesCount } = data;
          const { limit } = this.state;
          const max = Math.floor(propertiesCount / limit);

          return (
            <React.Fragment>
              <HeaderMeta seo={seo} />
              <SearchResults
                loading={loading}
                properties={properties}
                onNextPage={this.nextPage}
                onPrevPage={this.prevPage}
                page={this.state.page}
                max={max}
                count={propertiesCount}
                renderHeader={() => (
                  <SearchHeader
                    count={propertiesCount}
                    onOrderBy={this.orderBY}
                    search={this.state}
                  />
                )}
                renderSearch={() => (
                  <SearchBox
                    onStateUpdate={this.stateUpdate}
                    container={true}
                    onResetState={this.resetState}
                    onRoomUpdate={this.roomsUpdate}
                    onPropertyUpdate={this.propertyTypeUpdate}
                    onLocationUpdate={value =>
                      this.setState({ locations: value })
                    }
                    search={this.state}
                    saleType={"buttons"}
                  />
                )}
              />
            </React.Fragment>
          );
        }}
      </Query>
    );
  }
}
