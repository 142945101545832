"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.displayStyles = void 0;

var _variables = require("../../../../common/styles/variables");

var displayStyles = {
  tab: {
    color: _variables.colors.textDark,
    borderWidth: "0px",
    borderColor: "transparent",
    cursor: "pointer",
    // For CSS hover
    colorHover: _variables.colors.btnSecondary,
    hover: {
      color: _variables.colors.btnSecondary
    },
    active: {
      borderColor: _variables.colors.btnSecondary,
      color: _variables.colors.btnSecondary
    },
    disabled: {
      opacity: "0.5",
      cursor: "not-allowed"
    }
  }
};
exports.displayStyles = displayStyles;