import React from "react";
import { Query } from "react-apollo";
import { AREAS_QUERY } from "./queries/areas";
import { Footer, Loading, api } from "@pc/shared";

export class FooterContainer extends React.Component {
  render() {
    return (
      <Query query={AREAS_QUERY}>
        {({ loading, error, data }) => {
          if (error) return <div>{error}</div>;
          if (loading)
            return (
              <div>
                <Loading />
              </div>
            );
          const { areaAggregation: areaMenu } = data;

          return (
            <Footer
              primaryMenuItems={api.footer.primary}
              socialMenuItems={api.footer.social}
              areaMenuItems={areaMenu}
              legalMenuItems={api.footer.legal}
            />
          );
        }}
      </Query>
    );
  }
}
