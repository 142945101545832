import React from "react";
import { AGENTS_QUERY } from "./queries/agents";
import { Query } from "react-apollo";
import { Agents } from "./ui/Agents";
import { Loading } from "@pc/shared";
import { HeaderMeta } from "../../shared/ui/Helmet";

export class AgentsContainer extends React.Component {
  state = {
    open: false,
    agent: null,
    query: {},
    order: null
  };

  toggleModal = (state, agent) => {
    this.setState({
      open: state,
      agent
    });
  };

  setOrder = order => {
    this.setState({
      order: order
    });
  };

  onSearch = values => {
    const { locations } = values;
    const input = {};

    if (locations.length) {
      const locationQuery = {
        city: [],
        province: [],
        suburb: []
      };

      locations.forEach(l => {
        locationQuery[l.type] = [...locationQuery[l.type], l.value];
      });

      if (locationQuery.city.length) {
        input.city = locationQuery.city;
      }

      if (locationQuery.province.length) {
        input.province = locationQuery.province;
      }

      if (locationQuery.suburb.length) {
        input.suburb = locationQuery.suburb.map(s => parseInt(s, 10));
      }
    }

    this.setState({
      query: input
    });
  };

  sortAgents(agents) {
    if (this.state.order === "ascending") {
      return agents.sort((a, b) => (a.fullName > b.fullName ? 1 : -1));
    }
    if (this.state.order === "descending") {
      return agents.sort((a, b) => (a.fullName < b.fullName ? 1 : -1));
    }
    if (this.state.order === "propertyCountLtH") {
      return agents.sort((a, b) =>
        a.propertyCount > b.propertyCount ? 1 : -1
      );
    }
    if (this.state.order === "propertyCountHtL") {
      return agents.sort((a, b) =>
        a.propertyCount < b.propertyCount ? 1 : -1
      );
    }

    return agents;
  }

  render() {
    var seo = {
      title: "Property Cloud Real Estate Agents and Property Consultants"
    };
    return (
      <Query query={AGENTS_QUERY} variables={{ input: this.state.query }}>
        {({ loading, error, data }) => {
          if (error) return <div>{error}</div>;
          if (loading)
            return (
              <div>
                <Loading />
              </div>
            );

          const { agents } = data;

          return (
            <React.Fragment>
              <HeaderMeta seo={seo} />
              <Agents
                agents={this.sortAgents(agents)}
                onSearch={this.onSearch}
                onToggleModal={this.toggleModal}
                open={this.state.open}
                selectedAgent={this.state.agent}
                order={this.setOrder}
                currentOrder={this.state.order}
              />
            </React.Fragment>
          );
        }}
      </Query>
    );
  }
}
