"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sizeStyles = exports.displayStyles = void 0;

var _variables = require("../../../../common/styles/variables");

var displayStyles = {
  neutral: {
    color: _variables.colors.textDark,
    // For CSS hover
    colorHover: _variables.colors.btnBrand,
    hover: {
      color: _variables.colors.btnBrand
    }
  },
  primary: {
    color: _variables.colors.btnPrimary,
    // For CSS hover
    colorHover: _variables.colors.btnPrimaryHover,
    hover: {
      color: _variables.colors.btnPrimaryHover
    }
  },
  secondary: {
    color: _variables.colors.btnSecondary,
    // For CSS hover
    colorHover: _variables.colors.btnSecondaryHover,
    hover: {
      color: _variables.colors.btnSecondaryHover
    }
  }
};
exports.displayStyles = displayStyles;
var sizeStyles = {
  medium: {
    sm: {
      fontSize: "14px",
      lineHeigt: "16px"
    },
    md: {
      fontSize: "17px",
      lineHeigt: "26px"
    }
  },
  small: {
    sm: {
      fontSize: "12px",
      lineHeigt: "14px"
    },
    md: {
      fontSize: "12px",
      lineHeigt: "14px"
    }
  }
};
exports.sizeStyles = sizeStyles;