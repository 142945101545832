import React from "react";
import { Space, Text, Tab, Tabs, spacing } from "@pc/shared";
import { MortgageBond } from "./ui/MortgageBond";
import { Affordability } from "./ui/Affordability";
import { AdditionalPayment } from "./ui/AdditionalPayment";
import { BondAndTransfer } from "./ui/BondAndTransfer";

const tabs = [
  {
    label: "Mortgage Bond",
    component: MortgageBond
  },
  {
    label: "Affordability",
    component: Affordability
  },
  {
    label: "Additional Payment",
    component: AdditionalPayment
  },
  {
    label: "Bond and Transfer",
    component: BondAndTransfer
  }
];

export class CalculatorsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: 0,
      ...this.getInitValues(props)
    };
  }

  clearValues = e => {
    if (e) {
      e.preventDefault();
    }

    this.setState({
      ...this.getInitValues(this.props)
    });
  };

  getInitValues = props => ({
    //Morgage Bond (tab 0)
    purchasePrice: props.purchasePrice || 0,
    depositAmount: 0,
    interestRate: 10.25,
    loanTerm: 20,
    monthlyRepayment: 0,

    //Affordability (tab 1)
    grossMonthlyIncome: 0,
    nettMonthlyIncome: 0,
    totalMonthlyExpenses: 0,
    qualifiedLoanAmount: 0,

    //Additional Payments (tab 2)
    currentBondDept: 0,
    additionalMonthlyRepayment: 0,
    amountSaved: 0,
    currentLoanTerm_Years: 20,
    currentLoanTerm_Months: 0,
    reducedLoanTerm_Years: 20,
    reducedLoanTerm_Months: 0,
    monthlyRepayment_Including: 0,
    totalInterest_Including: 0,
    totalPayment_Including: 0,
    monthlyRepayment_Excluding: 0,
    totalInterest_Excluding: 0,
    totalPayment_Excluding: 0,

    //Bond and Transfer (tab 3)
    bondAndTransferCostsTotal: 0,

    bondRegistrationTotal: 0,
    bondRegistration_Cost: 0,
    bondRegistration_InitiationFee: 0,
    bondRegistration_Levy: 0,
    bondRegistration_Postage: 0,

    propertyTransferTotal: 0,
    propertyTransfer_Cost: 0,
    propertyTransfer_Levy: 0,
    propertyTransfer_Duty: 0,
    propertyTransfer_Postage: 0
  });

  updateState = (name, value) => {
    this.setState({ [name]: parseFloat(value) }, () => this.calculate());
  };

  switchTab = (e, switchTo) => {
    if (e) {
      e.preventDefault();
    }

    this.setState({ tab: switchTo });
  };

  calculate = e => {
    if (e) {
      e.preventDefault();
    }

    const { tab } = this.state;

    switch (tab) {
      case 0: //Morgage Bond
        this.getMonthlyRepayment();
        break;

      case 1: //Affordability Bond
        this.getQualifiedLoan();
        break;

      case 2: //Additional Payment
        this.getAdditionalPaymentSavings();
        break;

      case 3: //Bond and Transfer
        this.getBondAndTransferCosts();
        break;

      default:
        return "Something went horribly wrong...";
    }
  };

  getMonthlyRepayment = () => {
    const { purchasePrice, depositAmount, interestRate, loanTerm } = this.state;

    const monthlyInterestRate = interestRate / 1200;
    const repayment = Math.round(
      ((purchasePrice - depositAmount) * monthlyInterestRate) /
        (1 - Math.pow(1 / (1 + monthlyInterestRate), loanTerm * 12))
    );

    const currentBondDept = Math.floor(purchasePrice - depositAmount);
    const brTotal = this.getBondCosts(currentBondDept);
    const ptTotal = this.getTransferCosts(purchasePrice);
    const onceOffTotal = Math.floor(depositAmount + brTotal + ptTotal);
    const grossMonthlyIncome = (repayment / 3) * 10;

    this.setState({
      monthlyRepayment: Math.floor(repayment || 0),
      currentBondDept: Math.floor(currentBondDept || 0),
      propertyTransferTotal: Math.floor(ptTotal || 0),
      bondRegistrationTotal: Math.floor(brTotal || 0),
      bondAndTransferCostsTotal: Math.floor(onceOffTotal || 0),
      grossMonthlyIncome: Math.floor(grossMonthlyIncome || 0)
      //depositAmount: Math.floor(depositAmount || null)
    });
  };

  getQualifiedLoan = () => {
    const {
      grossMonthlyIncome,
      nettMonthlyIncome,
      totalMonthlyExpenses,
      interestRate,
      loanTerm
    } = this.state;

    const disposableIncome = nettMonthlyIncome - totalMonthlyExpenses;
    const maxLoanAffordability = grossMonthlyIncome * 0.3;

    var installment = 0;
    if (disposableIncome > 0) {
      var installment =
        disposableIncome >= maxLoanAffordability
          ? maxLoanAffordability
          : disposableIncome; // = monthly installment/repayment as calculated based on income/expenses
    } else {
      installment = maxLoanAffordability;
    }

    const monthlyInterestRate = interestRate / 1200;
    const term = loanTerm * 12;
    // Formula: payment = principal * monthly interest/(1 - (1/(1+MonthlyInterest)*Months))
    // thus
    const principal = Math.round(
      (installment - installment * Math.pow(1 + monthlyInterestRate, -term)) /
        monthlyInterestRate
    );

    this.setState({
      qualifiedLoanAmount: principal,
      monthlyRepayment: installment
    });
  };

  getLoanTermMonths = (
    monthlyRepayment,
    monthlyInterestRate,
    currentBondDept
  ) => {
    // FORMULA: N = –[ln(1 – [(PV * i) / PMT] ) / ln(1 + i)]
    // N = the number of months remaining
    // PV = present value, or outstanding loan balance
    // PMT = monthly payment
    // i = monthly interest rate

    const loanTerm_months =
      Math.log(
        (monthlyInterestRate + monthlyRepayment) /
          (monthlyRepayment - monthlyInterestRate * currentBondDept)
      ) / Math.log(1 + monthlyInterestRate);

    return loanTerm_months;
  };

  getAdditionalPaymentSavings = () => {
    const {
      monthlyRepayment,
      currentBondDept,
      additionalMonthlyRepayment,
      interestRate
    } = this.state;

    const repayment_NoAdditional = monthlyRepayment;
    const repayment_WithAdditional =
      monthlyRepayment + additionalMonthlyRepayment;
    const monthlyInterestRate = interestRate / (12 * 100);

    //Get the remaining term based on bond dept and current monthly payment:
    const term_months_NoAdditional = this.getLoanTermMonths(
      repayment_NoAdditional,
      monthlyInterestRate,
      currentBondDept
    );

    const quotient_NoAdditional = Math.floor(term_months_NoAdditional / 12);
    const remainder_NoAdditional = Math.floor(term_months_NoAdditional % 12);

    //Get the remaining term based on bond dept and additional monthly payment:
    const term_months_WithAdditional = this.getLoanTermMonths(
      repayment_WithAdditional,
      monthlyInterestRate,
      currentBondDept
    );

    const quotient_WithAdditional = Math.floor(term_months_WithAdditional / 12);
    const remainder_WithAdditional = Math.floor(
      term_months_WithAdditional % 12
    );

    const paid_NoAdditional = repayment_NoAdditional * term_months_NoAdditional;
    const paid_WithAdditional =
      repayment_WithAdditional * term_months_WithAdditional;

    const interest_excluding =
      repayment_NoAdditional * term_months_NoAdditional - currentBondDept;
    const interest_including =
      (repayment_NoAdditional + repayment_NoAdditional) *
        term_months_NoAdditional -
      currentBondDept;

    this.setState({
      loanTerm: Math.round(term_months_NoAdditional / 12), //years
      monthlyRepayment_Including: Math.floor(repayment_WithAdditional || null),
      monthlyRepayment_Excluding: Math.floor(repayment_NoAdditional || null),
      monthlyRepayment: Math.round(monthlyRepayment),
      currentLoanTerm_Years: Math.floor(quotient_NoAdditional || null),
      currentLoanTerm_Months: Math.floor(remainder_NoAdditional || null),
      reducedLoanTerm_Years: Math.floor(quotient_WithAdditional || null),
      reducedLoanTerm_Months: Math.floor(remainder_WithAdditional || null),
      amountSaved: Math.floor(paid_NoAdditional - paid_WithAdditional || null),
      totalInterest_Including: Math.floor(interest_including || null),
      totalInterest_Excluding: Math.floor(interest_excluding || null),
      totalPayment_Including: Math.floor(
        currentBondDept + interest_including || null
      ),
      totalPayment_Excluding: Math.floor(
        currentBondDept + interest_excluding || null
      )
    });
  };

  // http://www.ghostdigest.com/articles/schedule-of-fees-july-2018/55525
  getDeedsAmount_ForTransfer_ByPurchasePrice = purchasePrice => {
    var deedsAmount = 0;

    if (purchasePrice <= 100000) {
      deedsAmount = 36;
    } else if (purchasePrice > 100000 && purchasePrice <= 200000) {
      deedsAmount = 78;
    } else if (purchasePrice > 2000000 && purchasePrice <= 300000) {
      deedsAmount = 486;
    } else if (purchasePrice > 300000 && purchasePrice <= 600000) {
      deedsAmount = 606;
    } else if (purchasePrice > 600000 && purchasePrice <= 800000) {
      deedsAmount = 852;
    } else if (purchasePrice > 800000 && purchasePrice <= 1000000) {
      deedsAmount = 978;
    } else if (purchasePrice > 1000000 && purchasePrice <= 2000000) {
      deedsAmount = 1098;
    } else if (purchasePrice > 2000000 && purchasePrice <= 4000000) {
      deedsAmount = 1522;
    } else if (purchasePrice > 4000000 && purchasePrice <= 6000000) {
      deedsAmount = 1846;
    } else if (purchasePrice > 6000000 && purchasePrice <= 8000000) {
      deedsAmount = 2197;
    } else if (purchasePrice > 8000000 && purchasePrice <= 10000000) {
      deedsAmount = 2568;
    } else if (purchasePrice > 1000000 && purchasePrice <= 15000000) {
      deedsAmount = 3057;
    } else if (purchasePrice > 1500000 && purchasePrice <= 20000000) {
      deedsAmount = 3671;
    } else if (purchasePrice > 2000000) {
      deedsAmount = 4890;
    }

    return deedsAmount;
  };

  getDeedsAmount_ForBond_byBondDept = currentBondDept => {
    var deedsAmount = 0;

    if (currentBondDept <= 150000) {
      deedsAmount = 376;
    } else if (currentBondDept > 150000 && currentBondDept <= 300000) {
      deedsAmount = 486;
    } else if (currentBondDept > 300000 && currentBondDept <= 600000) {
      deedsAmount = 606;
    } else if (currentBondDept > 600000 && currentBondDept <= 800000) {
      deedsAmount = 852;
    } else if (currentBondDept > 800000 && currentBondDept <= 1000000) {
      deedsAmount = 978;
    } else if (currentBondDept > 1000000 && currentBondDept <= 2000000) {
      deedsAmount = 1098;
    } else if (currentBondDept > 2000000 && currentBondDept <= 4000000) {
      deedsAmount = 1522;
    } else if (currentBondDept > 4000000 && currentBondDept <= 6000000) {
      deedsAmount = 1846;
    } else if (currentBondDept > 6000000 && currentBondDept <= 8000000) {
      deedsAmount = 2197;
    } else if (currentBondDept > 8000000 && currentBondDept <= 10000000) {
      deedsAmount = 2568;
    } else if (currentBondDept > 10000000 && currentBondDept <= 15000000) {
      deedsAmount = 3057;
    } else if (currentBondDept > 15000000 && currentBondDept <= 20000000) {
      deedsAmount = 3671;
    } else if (currentBondDept > 20000000 && currentBondDept <= 30000000) {
      deedsAmount = 4278;
    } else if (currentBondDept > 30000000) {
      deedsAmount = 6113;
    }

    return deedsAmount;
  };

  //http://www.sars.gov.za/Tax-Rates/Pages/Transfer-Duty.aspx
  getTransferDuty_ByPurchasePrice = purchasePrice => {
    var transferDuty = 0;
    if (purchasePrice <= 900000) {
      transferDuty = 0;
    } else if (purchasePrice > 900000 && purchasePrice <= 1250000) {
      transferDuty = (purchasePrice - 900000) * 0.03;
    } else if (purchasePrice > 1250000 && purchasePrice <= 1750000) {
      transferDuty = 10500 + (purchasePrice - 1250000) * 0.06;
    } else if (purchasePrice > 1750000 && purchasePrice <= 2250000) {
      transferDuty = 40500 + (purchasePrice - 1750000) * 0.08;
    } else if (purchasePrice > 2250000 && purchasePrice <= 10000000) {
      transferDuty = 80500 + (purchasePrice - 2250000) * 0.11;
    } else if (purchasePrice > 10000000) {
      transferDuty = 933000 + (purchasePrice - 10000000) * 0.13;
    }

    return transferDuty;
  };

  // https://www.straussdaly.co.za/wp-content/uploads/2018/10/SD-Process-Costs-Brochure-2018.pdf
  // Scales and figures are exactly the same for both Bond Registration and Transfers
  getCost_ByDefiningValue = definingValue => {
    var cost = 0;
    //ToDo: Create formula instead...
    if (definingValue <= 150000) {
      cost = 5535;
    } else if (definingValue > 150000 && definingValue <= 200000) {
      cost = 6270;
    } else if (definingValue > 200000 && definingValue <= 250000) {
      cost = 7005;
    } else if (definingValue > 250000 && definingValue <= 300000) {
      cost = 7740;
    } else if (definingValue > 300000 && definingValue <= 350000) {
      cost = 8475;
    } else if (definingValue > 350000 && definingValue <= 400000) {
      cost = 9210;
    } else if (definingValue > 400000 && definingValue <= 450000) {
      cost = 9945;
    } else if (definingValue > 450000 && definingValue <= 500000) {
      cost = 10680;
    } else if (definingValue > 500000 && definingValue <= 600000) {
      cost = 12150;
    } else if (definingValue > 600000 && definingValue <= 700000) {
      cost = 13620;
    } else if (definingValue > 700000 && definingValue <= 800000) {
      cost = 15090;
    } else if (definingValue > 800000 && definingValue <= 900000) {
      cost = 16560;
    } else if (definingValue > 900000 && definingValue <= 1000000) {
      cost = 18030;
    } else if (definingValue > 1000000 && definingValue <= 1100000) {
      cost = 18765;
    } else if (definingValue > 1100000 && definingValue <= 1200000) {
      cost = 19500;
    } else if (definingValue > 1200000 && definingValue <= 1300000) {
      cost = 20235;
    } else if (definingValue > 1300000 && definingValue <= 1400000) {
      cost = 20970;
    } else if (definingValue > 1400000 && definingValue <= 1500000) {
      cost = 21705;
    } else if (definingValue > 1500000 && definingValue <= 1600000) {
      cost = 22440;
    } else if (definingValue > 1600000 && definingValue <= 1700000) {
      cost = 23175;
    } else if (definingValue > 1700000 && definingValue <= 1800000) {
      cost = 23910;
    } else if (definingValue > 1800000 && definingValue <= 1900000) {
      cost = 24645;
    } else if (definingValue > 1900000 && definingValue <= 2000000) {
      cost = 25380;
    } else if (definingValue > 2000000 && definingValue <= 2100000) {
      cost = 26115;
    } else if (definingValue > 2100000 && definingValue <= 2200000) {
      cost = 26850;
    } else if (definingValue > 2200000 && definingValue <= 2400000) {
      cost = 28320;
    } else if (definingValue > 2400000 && definingValue <= 2500000) {
      cost = 29055;
    } else if (definingValue > 2500000 && definingValue <= 2600000) {
      cost = 29790;
    } else if (definingValue > 2600000 && definingValue <= 2800000) {
      cost = 31260;
    } else if (definingValue > 2800000 && definingValue <= 3000000) {
      cost = 32730;
    } else if (definingValue > 3000000 && definingValue <= 3200000) {
      cost = 34200;
    } else if (definingValue > 3200000 && definingValue <= 3400000) {
      cost = 35670;
    } else if (definingValue > 3400000 && definingValue <= 3600000) {
      cost = 37140;
    } else if (definingValue > 3600000 && definingValue <= 3800000) {
      cost = 38610;
    } else if (definingValue > 3800000 && definingValue <= 4000000) {
      cost = 40080;
    } else if (definingValue > 4000000 && definingValue <= 4200000) {
      cost = 41550;
    } else if (definingValue > 4200000 && definingValue <= 4400000) {
      cost = 43020;
    } else if (definingValue > 4400000 && definingValue <= 4600000) {
      cost = 44490;
    } else if (definingValue > 4600000 && definingValue <= 4800000) {
      cost = 45960;
    } else if (definingValue > 4800000 && definingValue <= 5000000) {
      cost = 47430;
    } else if (definingValue > 5000000 && definingValue <= 5500000) {
      cost = 49280;
    } else if (definingValue > 5500000 && definingValue <= 6000000) {
      cost = 51130;
    } else if (definingValue > 6000000 && definingValue <= 6500000) {
      cost = 52980;
    } else if (definingValue > 6500000 && definingValue <= 7000000) {
      cost = 54830;
    } else if (definingValue > 7000000 && definingValue <= 7500000) {
      cost = 56680;
    } else if (definingValue > 7500000 && definingValue <= 8000000) {
      cost = 58530;
    } else if (definingValue > 8000000 && definingValue <= 8500000) {
      cost = 60380;
    } else if (definingValue > 8500000 && definingValue <= 9000000) {
      cost = 62230;
    } else if (definingValue > 9000000 && definingValue <= 9500000) {
      cost = 64080;
    } else if (definingValue > 9500000 && definingValue <= 10000000) {
      cost = 65930;
    } else if (definingValue > 10000000 && definingValue <= 20000000) {
      cost = 102930;
    } else if (definingValue > 20000000 && definingValue <= 30000000) {
      cost = 139930;
    } else if (definingValue > 30000000 && definingValue <= 40000000) {
      cost = 176930;
    } else if (definingValue > 40000000 && definingValue <= 50000000) {
      cost = 213930;
    } else if (definingValue > 50000000 && definingValue <= 60000000) {
      cost = 250930;
    } else if (definingValue > 60000000 && definingValue <= 70000000) {
      cost = 287930;
    } else if (definingValue > 70000000) {
      cost = 0; //To Be Implemented!
    }
    // ToDo: Set Env var for VAT value!
    //Add VAT
    return Math.floor(cost * 1.15);
  };

  getBondCosts = currentBondDept => {
    //Bond Registration Cost Breakdown:
    const br_Fee = 6037;
    const br_Postage = 2200;
    const br_Cost = this.getCost_ByDefiningValue(currentBondDept);
    const br_Levy = this.getDeedsAmount_ForBond_byBondDept(currentBondDept);
    const brTotal = br_Cost + br_Levy + br_Fee + br_Postage;

    this.setState({
      bondRegistration_Cost: br_Cost,
      bondRegistration_InitiationFee: br_Fee,
      bondRegistration_Levy: br_Levy,
      bondRegistration_Postage: br_Postage,
      bondRegistrationTotal: brTotal
    });

    return brTotal;
  };

  getTransferCosts = purchasePrice => {
    //Property Transfer Cost Breakdown:
    const pt_Postage = 4050;
    const pt_Cost = this.getCost_ByDefiningValue(purchasePrice);
    const pt_Levy = this.getDeedsAmount_ForTransfer_ByPurchasePrice(
      purchasePrice
    );
    const pt_Duty = this.getTransferDuty_ByPurchasePrice(purchasePrice);

    const ptTotal = pt_Postage + pt_Cost + pt_Levy + pt_Duty;

    this.setState({
      propertyTransfer_Cost: pt_Cost,
      propertyTransfer_Levy: pt_Levy,
      propertyTransfer_Duty: pt_Duty,
      propertyTransfer_Postage: pt_Postage,

      propertyTransferTotal: ptTotal
    });

    return ptTotal;
  };

  //ToDo: Set Env variables / System Settings
  getBondAndTransferCosts = () => {
    const { purchasePrice, currentBondDept } = this.state;

    const brTotal = this.getBondCosts(currentBondDept);
    const ptTotal = this.getTransferCosts(purchasePrice);

    this.setState({
      bondAndTransferCostsTotal: brTotal + ptTotal
    });
  };

  render() {
    const currentTab = tabs[this.state.tab];
    const TabComponent = currentTab.component;

    return (
      <React.Fragment>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <Space top bottom size={spacing.space4}>
                <Text display="title" color="brand" weight="medium">
                  Bond Calculators
                </Text>
              </Space>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <Tabs>
                {tabs.map((tab, index) => (
                  <Tab
                    active={index === this.state.tab}
                    onClick={() => this.setState({ tab: index })}
                  >
                    {tab.label}
                  </Tab>
                ))}
              </Tabs>
              <TabComponent
                data={this.state}
                onUpdateState={this.updateState}
                onCalculate={this.calculate}
                onClearValues={this.clearValues}
                onSwitchTab={this.switchTab}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
