import * as React from "react";
import {
  ContentModal,
  SliderWrapper,
  Hero,
  Card,
  Border,
  Text,
  Space,
  Box,
  Button,
  Image,
  spacing
} from "@pc/shared";
import clouds from "../../../common/images/bg_clouds.png";
import { FormContainer } from "../../Form/FormContainer";
import EnquiryForm from "../../Form/ui/EnquiryForm";

export const FranchisePage = props => {
  const { page } = props;

  return (
    <div>
      <Hero image={page.acf.hero.hero_image}>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <Space padding top bottom size={spacing.space15}>
                <Space margin bottom size={spacing.space6}>
                  <Text
                    display="headline"
                    color="white"
                    weight="medium"
                    html={page.acf.hero.hero_headline}
                  />
                </Space>
                {page.acf.hero.button.label && (
                  <Button
                    as="a"
                    href={page.acf.hero.button.url}
                    display="alternative"
                  >
                    {page.acf.hero.button.label}
                  </Button>
                )}
              </Space>
            </div>
          </div>
        </div>
      </Hero>

      <div class="container">
        <div className="row">
          <div className="col-md-8 m-auto">
            <Space margin top bottom size={spacing.space15}>
              <Space margin bottom size={spacing.space4}>
                <Text
                  align="center"
                  display="title"
                  color="brand"
                  weight="medium"
                  html={page.acf.headline}
                />
              </Space>
              <Text
                align="center"
                display="body"
                weight="light"
                html={page.content}
              />
            </Space>
          </div>
        </div>
      </div>

      <div class="container-fluid">
        <Space margin bottom size={spacing.space15}>
          <div className="col-lg-10 offset-lg-1">
            <Box
              borderRadius
              backgroundColor="dark"
              backgroundImage={clouds}
              backgroundRepeat="no-repeat"
              backgroundSize="contain"
              backgroundPosition="center 20%"
            >
              <Space padding left right size={spacing.space2}>
                <Space padding top bottom size={spacing.space15}>
                  <div className="row">
                    {page.acf.pitch.subtitle && (
                      <div className="col-lg-11 offset-lg-1">
                        <Text display="meta" color="brandPrimary">
                          {page.acf.pitch.subtitle}
                        </Text>
                      </div>
                    )}

                    <div className="col-lg-5 offset-lg-1">
                      {page.acf.pitch.title && (
                        <Space bottom size={spacing.space4}>
                          <Text
                            display="title"
                            color="brandPrimary"
                            html={page.acf.pitch.title}
                          />
                        </Space>
                      )}

                      {page.acf.pitch.description && (
                        <Text
                          as="p"
                          display="meta"
                          color="brandPrimary"
                          html={page.acf.pitch.description}
                        />
                      )}
                    </div>

                    {page.acf.pitch.pitch_content && (
                      <div className="col-lg-5">
                        <Text
                          color="body"
                          weight="light"
                          html={page.acf.pitch.pitch_content}
                        />
                      </div>
                    )}
                  </div>
                </Space>
              </Space>
            </Box>
          </div>
        </Space>
      </div>

      <div class="container">
        {page.acf.features && (
          <Space margin bottom size={spacing.space15}>
            <div className="row">
              <div className="col-md-12">
                <Space margin bottom size={spacing.space8}>
                  <Text
                    display="title"
                    color="brand"
                    weight="medium"
                    align="center"
                    html={page.acf.features.headline}
                  />
                </Space>
              </div>
            </div>
            <div className="row align-items-center ">
              {page.acf.features.image && (
                <div className="col-md-6">
                  <Space top bottom size={spacing.space2}>
                    <Image fluid src={page.acf.features.image} />
                  </Space>
                </div>
              )}

              <div className="col-md-5">
                <SliderWrapper slidesToShow={1} dots={true} fade={false}>
                  {page.acf.features.features.map(s => (
                    <Space padding left right size={spacing.space2}>
                      <Space margin bottom size={spacing.space2}>
                        <Text
                          display="titleSmall"
                          color="dark"
                          weight="medium"
                          html={s.title}
                        />
                      </Space>
                      <Text display="body" weight="light" html={s.content} />
                    </Space>
                  ))}
                </SliderWrapper>
              </div>
            </div>
          </Space>
        )}

        <Border />

        {page.acf.benefits && (
          <Space margin top bottom size={spacing.space10}>
            <div className="row">
              <div className="col-12 text-center">
                <Space margin bottom size={spacing.space8}>
                  <Text
                    align="center"
                    display="title"
                    color="brand"
                    weight="medium"
                  >
                    {page.acf.benefits.headline}
                  </Text>
                </Space>
              </div>
            </div>
            <div className="row justify-content-around">
              {page.acf.benefits.items.map((b, index) => {
                return (
                  <div className="col-md-6 col-lg-3 text-center">
                    <Card index={index} image={b.image} title={b.title}>
                      <Text display="body" weight="light" html={b.content} />
                    </Card>
                  </div>
                );
              })}
            </div>
          </Space>
        )}

        {page.acf.cta && (
          <Space margin top bottom size={spacing.space15}>
            <Box borderRadius boxShadow>
              <Space padding top bottom left right size={spacing.space8}>
                <div className="row">
                  <div className="col-md-8">
                    <Space margin bottom size={spacing.space2}>
                      <Text
                        display="titleSmall"
                        color="brand"
                        html={page.acf.cta.title}
                      />
                    </Space>
                    <Text
                      display="body"
                      color="dark"
                      weight="light"
                      html={page.acf.cta.description}
                    />
                    {page.acf.cta.button_options.button_label && (
                      <Button onClick={() => props.onToggleModal(!props.open)}>
                        {page.acf.cta.button_options.button_label}
                      </Button>
                    )}
                  </div>
                  <div className="col-md-4">
                    <Image src={page.acf.cta.image} />
                  </div>
                </div>
              </Space>
            </Box>
            <ContentModal
              isOpen={props.open}
              closeModal={() => props.onToggleModal(false)}
              renderTitle={() => (
                <React.Fragment>
                  <Text
                    display="title"
                    color="brandPrimary"
                    weight="medium"
                    html={page.acf.cta.button_options.button_label}
                  />
                  <Space top bottom size={spacing.space2}>
                    <Border />
                  </Space>
                </React.Fragment>
              )}
              render={() => (
                <Space padding right bottom left size={spacing.space3}>
                  <Space top bottom size={spacing.space4}>
                    <FormContainer
                      formId={"join"}
                      render={form => {
                        return <EnquiryForm form={form} />;
                      }}
                    />
                  </Space>
                </Space>
              )}
            />
          </Space>
        )}
      </div>
    </div>
  );
};
