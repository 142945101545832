import React from "react";
import { Space, Text, spacing, Border, ButtonText } from "@pc/shared";
import EnquiryForm from "../../Form/ui/EnquiryForm";
import { FormContainer } from "../../Form/FormContainer";

export const ContentAgent = props => {
  const {
    fullName,
    role,
    email,
    mobile,
    about,
    property,
    agentId,
    showAbout,
    social
  } = props;

  return (
    <Space padding right bottom left size={spacing.space3}>
      <Space margin bottom size={spacing.space4}>
        <Text display="title" color="brandPrimary" weight="medium">
          {fullName}
        </Text>

        {role && (
          <Space margin bottom size={spacing.space2}>
            <Text display="meta" color="brandPrimary">
              {role}
            </Text>
          </Space>
        )}

        {email && (
          <Space inline margin right size={spacing.space4}>
            <ButtonText
              iconBefore="envelope"
              display="primary"
              inline
              as="a"
              href={`mailto:${email}`}
            >
              {/* Keep Text component. Need to fix styling issue and then implement. */}
              {/* <Text truncate html={email} /> */}
              {email}
            </ButtonText>
          </Space>
        )}

        {mobile && (
          <Space inline margin right size={spacing.space4}>
            <ButtonText
              iconBefore="phone"
              display="primary"
              inline
              as="a"
              href={`tel:${mobile}`}
            >
              {mobile}
            </ButtonText>
          </Space>
        )}
      </Space>

      {showAbout && about && (
        <Space margin bottom size={spacing.space4}>
          <Text color="body" as="p" weight="light">
            {about}
          </Text>
        </Space>
      )}
      {social ? (
        <React.Fragment>
          {social.facebook && (
            <Space inline margin right size={spacing.space4}>
              <ButtonText
                as="a"
                inline
                display="primary"
                iconBefore="facebook"
                target="_blank"
                href={social.facebook}
              >
                Facebook
              </ButtonText>
            </Space>
          )}
          {social.twitter && (
            <Space inline margin right size={spacing.space4}>
              <ButtonText
                inline
                as="a"
                display="primary"
                iconBefore="twitter"
                target="_blank"
                href={social.twitter}
              >
                Twitter
              </ButtonText>
            </Space>
          )}
          {social.instagram && (
            <Space inline margin right size={spacing.space4}>
              <ButtonText
                inline
                as="a"
                display="primary"
                iconBefore="instagram"
                target="_blank"
                href={social.instagram}
              >
                Instagram
              </ButtonText>
            </Space>
          )}
          {social.youtube && (
            <Space inline margin right size={spacing.space4}>
              <ButtonText
                inline
                as="a"
                display="primary"
                iconBefore="youtube"
                target="_blank"
                href={social.youtube}
              >
                YouTube
              </ButtonText>
            </Space>
          )}
          {social.linkedin && (
            <Space inline margin right size={spacing.space4}>
              <ButtonText
                inline
                as="a"
                display="primary"
                iconBefore="linkedin"
                target="_blank"
                href={social.linkedin}
              >
                LinkedIn
              </ButtonText>
            </Space>
          )}
          {social.whatsapp && (
            <Space inline margin right size={spacing.space4}>
              <ButtonText
                inline
                as="a"
                display="primary"
                iconBefore="whatsapp"
                target="_blank"
                href={`https://api.whatsapp.com/send?phone=${
                  social.whatsapp
                }&text=&source=&data=`}
              >
                WhatsApp
              </ButtonText>
            </Space>
          )}
        </React.Fragment>
      ) : null}
      <Space margin top bottom size={spacing.space4}>
        <Border />
      </Space>

      <FormContainer
        formId={"enquiry"}
        render={form => {
          return (
            <EnquiryForm property={property} agent={agentId} form={form} />
          );
        }}
      />
    </Space>
  );
};

ContentAgent.defaultProps = {
  showAbout: false,
  showSocial: false
};
