import React from "react";
import gql from "graphql-tag";
import { Text, Space } from "@pc/shared";
const fragments = {
  fields: gql`
    fragment ContentFields on ContentBlock {
      label
      description
      tags
    }
  `
};

function ContentBlock(props) {
  return (
    <Space bottom size="space2">
      <Text display="small" color="dark" weight="medium">
        {props.label}
      </Text>
      {props.description ? (
        <Text
          display="small"
          color="dark"
          weight="light"
          html={props.description}
        />
      ) : null}
      {props.tags ? (
        <Text display="small" color="dark" weight="light">
          {props.tags}
        </Text>
      ) : null}
    </Space>
  );
}

ContentBlock.fragments = fragments;

export default ContentBlock;
