import * as React from "react";
import { Link } from "react-router-dom";
import {
  Space,
  ButtonText,
  Border,
  CardProfile,
  CardProperty,
  ButtonAction,
  Loading,
  Text,
  SliderWrapper,
  Image,
  Box,
  spacing
} from "@pc/shared";
import { formatCurrency } from "../../../common/format";
import gql from "graphql-tag";
import { icons } from "../../../common/content";

const fragments = {
  fields: gql`
    fragment PropertySearchInfo on Property {
      price
      mandateID
      suburb {
        suburbName
        city
        province
        id
      }
      images {
        id
        imageServer
      }
      agents {
        fullName
        subtitle
        image {
          url
        }
      }
      counts {
        Bedroom
        Bathroom
        Garage
      }
    }
  `
};

export const SearchResults = ({
  loading,
  properties,
  renderSearch,
  renderHeader,
  page,
  max,
  count,
  onNextPage,
  onPrevPage
}) => {
  return (
    <React.Fragment>
      <Border />
      <div className="container-full">
        {renderSearch ? renderSearch() : null}
      </div>

      {renderHeader ? renderHeader() : null}
      {loading ? (
        <div className="container">
          <Loading />
        </div>
      ) : (
        <div className="container">
          {!properties.length ? (
            <Box borderRadius backgroundColor="dark">
              <Space bottom size={spacing.space4}>
                <Space padding left right top bottom size={spacing.space10}>
                  <Text
                    display="meta"
                    align="center"
                    color="brandPrimary"
                    html={
                      "Unfortunately we have no results for your search query.</br>Please refine your search or visit the agents page to get in contact with one of our agents."
                    }
                  />
                </Space>
              </Space>
            </Box>
          ) : null}
          {properties.map(p => (
            <Space margin bottom size={spacing.space6}>
              <CardProperty
                size={"large"}
                display={"horizontal"}
                title={formatCurrency(p.price)}
                meta={`${p.suburb.city}, ${p.suburb.suburbName}`}
                renderVisual={
                  <SliderWrapper pager lazy={true}>
                    {p.images.map(image => (
                      <div key={image.id}>
                        <Image fluid src={image.imageServer} />
                      </div>
                    ))}
                  </SliderWrapper>
                }
                renderCta={hover => (
                  <React.Fragment>
                    <Space margin bottom size={spacing.space3}>
                      <Text display="small" color="dark">
                        {p.summary}
                      </Text>
                    </Space>

                    <ButtonText
                      as={Link}
                      to={`/property/${p.mandateID}`}
                      hover={hover}
                      inline
                      display="secondary"
                      iconAfter="angle-right"
                    >
                      View property
                    </ButtonText>
                  </React.Fragment>
                )}
              >
                {["Bedroom", "Bathroom", "Garage"].map(key =>
                  p.counts && p.counts[key] ? (
                    <Space inline margin right size={spacing.space2}>
                      <Text inline display="metaSmall">
                        <i className={`icon icon-${icons[key]}`} />{" "}
                        {p.counts[key]}
                      </Text>
                    </Space>
                  ) : null
                )}

                <div className="row">
                  <div className="col-12">
                    <Space margin top bottom size={spacing.space3}>
                      <Border />
                    </Space>
                    {p.agents
                      .filter((a, index) => index === 0)
                      .map(agent => {
                        return (
                          <CardProfile
                            display="full"
                            title={agent.fullName}
                            role={agent.subtitle || "Property Consultant"}
                            image={agent.image ? agent.image.url : null}
                          />
                        );
                      })}
                  </div>
                </div>
              </CardProperty>
            </Space>
          ))}

          <Space margin bottom size={spacing.space10}>
            <div className="row">
              <div className="col-12">
                <div className="row justify-content-between">
                  <div className="col-4 col-md-2">
                    {page > 1 ? (
                      <ButtonAction
                        onClick={onPrevPage}
                        iconBefore="caret-left"
                      >
                        Previous
                      </ButtonAction>
                    ) : null}
                  </div>
                  <div className="col-4 col-md-2">
                    {page < max ? (
                      <ButtonAction
                        onClick={onNextPage}
                        iconAfter="caret-right"
                      >
                        Next
                      </ButtonAction>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </Space>
        </div>
      )}
    </React.Fragment>
  );
};

SearchResults.fragments = fragments;
