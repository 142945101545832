import * as React from "react";
import styled from "styled-components";
import { ButtonText } from "@pc/shared";

class ShareBox extends React.Component {
  state = {
    toggleMenu: false
  };

  handleClick = () => {
    this.setState({
      toggleMenu: !this.state.toggleMenu
    });
  };

  render() {
    const StyledOverlay = styled.div`
      position: absolute;
      top: 30px;
      left: 0px;
      right: 0px;
      z-index: 10;
      box-shadow: rgba(0, 0, 0, 0.15) 0px 14px 36px 2px;
      overflow-y: auto;
      overflow-x: hidden;
      white-space: normal;
      display: inline-block;
      max-height: calc(100vh - 168px);
      visibility: visible;
      background: rgb(255, 255, 255);
      border-width: 1px;
      border-style: solid;
      border-color: rgba(0, 0, 0, 0.2);
      border-image: initial;
      border-radius: 4px;
      padding: 16px 24px;
      min-width: 100px;
    `;

    const { label } = this.props;

    return (
      <span className="mr-md-3 mb-3 mb-md-0">
        <ButtonText iconBefore="share" onClick={() => this.handleClick()}>
          {label}
        </ButtonText>

        <div
          className="menu_box"
          style={{ position: "relative", width: "300px" }}
        >
          {this.state.toggleMenu && (
            <StyledOverlay>
              {this.props.children}

              <div className="text-right">
                <ButtonText
                  display="primary"
                  onClick={() => this.setState({ toggleMenu: false })}
                >
                  Close
                </ButtonText>
              </div>
            </StyledOverlay>
          )}
        </div>
      </span>
    );
  }
}

ShareBox.defaultProps = {
  selected: [],
  single: "Region",
  plural: "Regions",
  placeholder: "All Regions"
};

export default ShareBox;
