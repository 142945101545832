"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledIcon = exports.StyledButtonAction = void 0;

var _react = _interopRequireDefault(require("react"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _variables = require("../../../../common/styles/variables");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  margin-right: ", ";\n  margin-left: ", ";\n  font-size: 16px;\n  line-height: 19px;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  padding: ", ";\n  font-size: 14px;\n  line-height: 16px;\n  border-color: ", ";\n  background-color: ", ";\n  color: ", ";\n  border-width: 1px;\n  border-style: solid;\n  border-radius: 3px;\n  margin-bottom: 0;\n  cursor: pointer;\n  text-align: center;\n  font-family: ", ";\n  font-weight: ", ";\n  transition: all ", ";\n  display: ", ";\n  width: ", ";\n  :focus,\n  :hover {\n    background-color: ", ";\n    border-color: ", ";\n    color: ", " !important;\n    outline: 0;\n    text-decoration: none;\n  }\n  /* Medium Screens */\n  @media (min-width: 768px) {\n    font-size: 16px;\n    line-height: 19px;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

var Button = function Button(_ref) {
  var B = _ref.as,
      props = _objectWithoutProperties(_ref, ["as"]);

  return _react.default.createElement(B, props);
};

var StyledButtonAction = (0, _styledComponents.default)(Button)(_templateObject(), function (props) {
  return props.iconBefore || props.iconAfter ? "6px 10px;" : "6px 20px;";
}, _variables.colors.borderBase, _variables.colors.bgWhite, _variables.colors.textDark, _variables.fonts.sans, _variables.fontWeight.light, _variables.timing.fast, function (props) {
  return props.block ? "block" : "inline-block";
}, function (props) {
  return props.block ? "100%" : "inherit";
}, _variables.colors.btnPrimary, _variables.colors.btnPrimary, _variables.colors.textWhite);
exports.StyledButtonAction = StyledButtonAction;

var StyledIcon = _styledComponents.default.i(_templateObject2(), function (props) {
  return props.iconBefore ? "0.5rem" : "0";
}, function (props) {
  return props.iconAfter ? "0.5rem" : "0";
});

exports.StyledIcon = StyledIcon;