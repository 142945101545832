export const propertyTypes = [
  {
    value: "House",
    label: "House"
  },
  {
    value: "FlatApartment",
    label: "Flat / Apartment"
  },
  {
    value: "Townhouse",
    label: "Townhouse"
  },
  {
    value: "Commercial",
    label: "Commercial"
  },
  {
    value: "Industrial",
    label: "Industrial"
  },
  {
    value: "VacantLand",
    label: "Vacant Land"
  }
];

export const getImages = (image, w, h) => {
  return `${process.env.RAZZLE_IMAGE_URL}/image/${w}/${h}/${image}.jpg`;
};
