import gql from "graphql-tag";

export const AGENTS_QUERY = gql`
  query agents($input: AgentInput) {
    agents(input: $input) {
      agentId
      fullName
      firstName
      lastName
      email
      work
      mobile
      active
      image {
        FileId
        url
      }
      medianPrice
      social {
        facebook
        twitter
        instagram
        youtube
        linkedin
        whatsapp
      }
      website {
        address
      }
      propertyCount
      subtitle
    }
  }
`;
