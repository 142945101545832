import React from "react";
import Rheostat from "rheostat";
import "rheostat/css/rheostat.css";
import { Space, Text, Box, spacing } from "@pc/shared";
import { formatCurrency } from "../../../common/format";

export class Affordability extends React.Component {
  componentDidMount() {
    this.props.onCalculate();
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-4">
          <Space padding right bottom left size="20px">
            <Space margin bottom size={spacing.space2}>
              <Text display="small" weight="medium">
                Gross Monthly Income
              </Text>
              <input
                className="form-control"
                type="number"
                name="grossMonthlyIncome"
                value={this.props.data.grossMonthlyIncome}
                onChange={event =>
                  this.props.onUpdateState(
                    event.target.name,
                    event.target.value
                  )
                }
              />
            </Space>
            <Space margin bottom top size={spacing.space2}>
              <Text display="small" weight="medium">
                Nett Monthly Income
              </Text>
              <input
                type="number"
                className="form-control"
                name="nettMonthlyIncome"
                value={this.props.data.nettMonthlyIncome}
                onChange={event =>
                  this.props.onUpdateState(
                    event.target.name,
                    event.target.value
                  )
                }
              />
            </Space>
            <Space margin bottom top size={spacing.space2}>
              <Text display="small" weight="medium">
                Total Monthly Expenses
              </Text>
              <input
                type="number"
                className="form-control"
                name="totalMonthlyExpenses"
                value={this.props.data.totalMonthlyExpenses}
                onChange={event =>
                  this.props.onUpdateState(
                    event.target.name,
                    event.target.value
                  )
                }
              />
            </Space>
            <Space margin bottom top size={spacing.space2}>
              <Text display="small" weight="medium">
                Interest Rate
              </Text>
              <input
                className="form-control"
                type="number"
                step="any"
                name="interestRate"
                value={this.props.data.interestRate}
                onChange={event =>
                  this.props.onUpdateState(
                    event.target.name,
                    event.target.value
                  )
                }
              />
            </Space>
            <Space margin top size={spacing.space2}>
              <Text display="small" weight="medium">
                Loan Term: {this.props.data.loanTerm} years
              </Text>
              <Rheostat
                min="1"
                max="30"
                onValuesUpdated={this.updateValue}
                values={[this.props.data.loanTerm]}
              />
            </Space>
          </Space>
        </div>

        <div className="col-md-8">
          <Box border borderRadius>
            <Space padding right left size={spacing.space4}>
              <Space margin top bottom size={spacing.space3}>
                <Space margin bottom size={spacing.space1}>
                  <div className="d-flex justify-content-between">
                    <Text display="titleTiny" color="brand">
                      Qualified Loan Amount
                    </Text>
                    <Text
                      display="titleTiny"
                      color="brand"
                      weight="medium"
                      html={formatCurrency(this.props.data.qualifiedLoanAmount)}
                    />
                  </div>
                </Space>
              </Space>

              <Space margin top bottom size={spacing.space3}>
                <Space margin top bottom size={spacing.space1}>
                  <div className="d-flex justify-content-between">
                    <Text display="titleTiny" color="brand">
                      Monthly Repayment
                    </Text>
                    <Text
                      display="titleTiny"
                      color="brand"
                      weight="medium"
                      html={formatCurrency(this.props.data.monthlyRepayment)}
                    />
                  </div>
                </Space>
              </Space>
            </Space>
          </Box>
          <Space margin top bottom size={spacing.space3}>
            <Box>
              <Space margin bottom size={spacing.space3}>
                <Text
                  display="titleTiny"
                  color="dark"
                  weight="medium"
                  align="justify"
                >
                  How are home loans calculated?
                </Text>
              </Space>
              <Space margin top bottom size={spacing.space3}>
                <Text
                  display="tiny"
                  color="dark"
                  weight="light"
                  align="justify"
                >
                  When it comes to applying for a home loan, South African banks
                  will, typically, allow you to qualify for a home loan
                  repayment of up to 30% of your joint, gross, monthly income
                  i.e. your total joint income before tax and expenses have been
                  deducted. This rule ensures that banks adhere to responsible
                  credit lending as mandated by the National Credit Act of South
                  Africa.
                  <br />
                  <br />
                  The next item that banks will look at is your nett monthly
                  income minus your total monthly expenses. The banks want to
                  see that your disposable income will cover the monthly
                  repayments of the home loan.
                  <br />
                  <br />
                  Make use of our affordability calculator above to find out
                  what your estimated home loan amount will be. We have set the
                  default interest rate to the current prime lending rate. Based
                  on your credit profile, South African banks may choose to give
                  you an interest rate either higher or lower than the prime
                  lending rate. A small change in your interest rate can have a
                  significant impact on your final home loan amount. It is very
                  important to keep this in mind when estimating the amount for
                  which you qualify.
                </Text>
              </Space>
            </Box>
          </Space>
        </div>
      </div>
    );
  }
}
