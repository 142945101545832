import React from "react";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import PropertyTabs from "./PropertyTabs";

const PropertyOverview = props => {
  if (props.loading) return null;
  return props.property.overview.map(o => {
    if (o.__typename === "Tabs") {
      return <PropertyTabs {...o} />;
    }

    return null
  });
};

const fragments = {
  topLevel: gql`
    fragment PropertyInfo on Property {
      overview {
        ...TabFields
      }
    }
    ${PropertyTabs.fragments.fields}
  `
};

export default graphql(
  gql`
    query PropertyRequest($id: Int!) {
      property(mandateID: $id) {
        ...PropertyInfo
      }
    }
    ${fragments.topLevel}
  `,
  {
    options: props => {
      return {
        variables: { id: props.id }
      };
    },
    props: ({ data }) => ({ ...data })
  }
)(PropertyOverview);
