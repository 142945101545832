import gql from "graphql-tag";

export const PROPERTY_QUERY = gql`
  query property($mandateID: Int!) {
    property(mandateID: $mandateID) {
      title
      mandateType
      listingNumber
      nextProperty {
        mandateID
      }
      previousProperty {
        mandateID
      }
      price
      propertyType
      description
      images {
        id
        imageServer
      }
      agents {
        agentId
        fullName
        mobile
        email
        image {
          FileId
          url
        }
      }
      mandateID
      counts {
        Bedroom
        Bathroom
        Garage
      }
      meta {
        levy
        rates
        specialLevy
        floor {
          Size
        }
      }
      suburb {
        id
        country
        postalCode
        province
        city
        suburbName
        breadcrumbs {
          slug
          label
        }
      }

      agents {
        agentId
        firstName
        lastName
      }
    }
  }
`;
