import gql from "graphql-tag";

export const SEARCH_QUERY = gql`
  query properties($input: PropertyInput, $limit: Int, $page: Int) {
    propertiesCount(input: $input)
    properties(input: $input, limit: $limit, page: $page) {
      agents {
        agentId
        fullName
        mobile
        email
        subtitle
        image {
          url
        }
      }
      images {
        id
        imageServer
      }
      summary
      title
      listingNumber
      price
      propertyType
      images {
        id
        imageServer
      }
      mandateID
      mandateType
      counts {
        Bedroom
        Bathroom
        Garage
      }
      suburb {
        id
        country
        postalCode
        province
        city
        suburbName
      }
    }
  }
`;
