import React, { Component } from "react";
import AsyncSelect from "react-select/lib/Async";
import { withApollo } from "react-apollo";
import { SEARCH_SUBURBS } from "./queries/suburbs";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    padding: 10
  }),
  control: (provided, state) => ({
    ...provided,
    background: "transparent",
    border: "none",
    boxShadow: "none",
    minHeight: "60px",
    ":hover": {
      background: "transparent",
      border: "none"
    },
    ":focus": {
      background: "transparent",
      border: "none"
    }
  }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      borderColor: "#093660",
      backgroundColor: "#093660",
      padding: "11.5px 35px",
      fontSize: "17px",
      fontWeight: 500,
      borderWidth: "1px",
      borderStyle: "solid",
      borderRadius: "3px",
      fontFamily: "'faricy-new-web','Helvetica Neue',Helvetica,Arial,sans-serif"
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: "#fff"
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: "#fff",
    ":hover": {
      color: "#028595"
    }
  })
};

class AutoComplete extends Component {
  state = { inputValue: "" };
  handleInputChange = newValue => {
    const inputValue = newValue.replace(/\W/g, "");
    this.setState({ inputValue });
    return inputValue;
  };

  loadSuburbs = async inputValue => {
    const { client } = this.props;
    const { data } = await client.query({
      query: SEARCH_SUBURBS,
      variables: { keyword: inputValue }
    });

    return data.searchSuburbs;
  };
  render() {
    return (
      <AsyncSelect
        styles={customStyles}
        className="w-100"
        isMulti
        cacheOptions
        defaultOptions
        value={this.props.locations}
        onChange={this.props.onLocationUpdate}
        loadOptions={this.loadSuburbs}
        placeholder={
          this.props.searchPlaceholder
            ? this.props.searchPlaceholder
            : "Enter city or suburb name"
        }
      />
    );
  }
}

export default withApollo(AutoComplete);
