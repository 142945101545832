"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledIcon = exports.StyledButtonText = void 0;

var _react = _interopRequireDefault(require("react"));

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _style = require("./style");

var _variables = require("../../../../common/styles/variables");

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {}; if (desc.get || desc.set) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  margin-right: ", ";\n  margin-left: ", ";\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  ", ";\n  ", ";\n  background: none;\n  border: none;\n  padding: 0px;\n  cursor: pointer;\n  font-family: ", ";\n  font-weight: ", ";\n  transition: all ", ";\n  display: ", ";\n  :hover {\n    outline: 0;\n    text-decoration: none;\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n    font-size: ", ";\n    line-height: ", ";\n    /* Medium Screens */\n    @media (min-width: 768px) {\n      font-size: ", ";\n      line-height: ", ";\n    }\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n    color: ", ";\n    :focus,\n    :hover {\n      color: ", " !important;\n      outline: 0;\n      text-decoration: none;\n    }\n  "]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var display = function display(props) {
  var style = _style.displayStyles[props.display];

  if (props.hover) {
    style = _objectSpread({}, style, _style.displayStyles[props.display].hover);
  }

  return (0, _styledComponents.css)(_templateObject(), style.color, style.colorHover);
};

var size = function size(props) {
  var style = _style.sizeStyles[props.size];
  return (0, _styledComponents.css)(_templateObject2(), style.sm.fontSize, style.sm.lineHeight, style.md.fontSize, style.md.lineHeight);
};

var Button = function Button(_ref) {
  var B = _ref.as,
      props = _objectWithoutProperties(_ref, ["as"]);

  return _react.default.createElement(B, props);
};

var StyledButtonText = (0, _styledComponents.default)(Button)(_templateObject3(), display, size, _variables.fonts.sans, function (props) {
  return props.iconBefore || props.iconAfter ? _variables.fontWeight.regular : _variables.fontWeight.medium;
}, _variables.timing.fast, function (props) {
  return props.block ? "block" : "inline-block";
});
exports.StyledButtonText = StyledButtonText;

var StyledIcon = _styledComponents.default.i(_templateObject4(), function (props) {
  return props.iconBefore ? "0.5rem" : "0";
}, function (props) {
  return props.iconAfter ? "0.5rem" : "0";
});

exports.StyledIcon = StyledIcon;