"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _style = require("./style");

var _variables = require("../../../common/styles/variables");

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {}; if (desc.get || desc.set) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } } newObj.default = obj; return newObj; } }

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  ", ";\n  margin: 0;\n  line-height: 1;\n  border-radius: 50%;\n  transition: all ", ";\n  display: inline-block;\n  display: flex;\n  flex-shrink: 0;\n  align-items: center;\n  justify-content: center;\n  border-style: solid;\n  border-width: 1px;\n  width: 32px;\n  height: 32px;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n    color: ", ";\n    border-color: ", ";\n    background-color: ", ";\n    opacity: ", ";\n    cursor: ", ";\n    text-decoration: none;\n    :focus,\n    :hover {\n      background-color: ", ";\n      border-color: ", ";\n      color: ", ";\n      outline: 0;\n    }\n  "]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var defaultProps = {
  display: "base"
};

var display = function display(props) {
  var style = _style.displayStyles[props.display];

  if (props.hover) {
    style = _objectSpread({}, style, _style.displayStyles[props.display].hover);
  }

  if (props.checked) {
    style = _objectSpread({}, style, _style.displayStyles[props.display].checked);
  }

  if (props.disabled) {
    style = _objectSpread({}, style, _style.displayStyles[props.display].disabled);
  }

  return (0, _styledComponents.css)(_templateObject(), style.color, style.border, style.background, style.opacity, style.cursor, style.bgColorHover, style.borderColorHover, style.colorHover);
};

var StyledInputCheckBox = _styledComponents.default.span(_templateObject2(), display, _variables.timing.slow);

var _default = StyledInputCheckBox;
exports.default = _default;
StyledInputCheckBox.defaultProps = defaultProps;