import React from "react";
import { Query } from "react-apollo";
import { Loading } from "@pc/shared";
import { PROPERTY_QUERY } from "./queries/property";
import { Property } from "./ui/Property";
import { HeaderMeta } from "../../shared/ui/Helmet";
import { getImages } from "../../common/options";
export class PropertyContainer extends React.Component {
  render() {
    const {
      match: {
        params: { propertyId }
      }
    } = this.props;

    let mandateID = parseInt(propertyId, 10);

    return (
      <Query query={PROPERTY_QUERY} variables={{ mandateID: mandateID }}>
        {({ loading, error, data }) => {
          if (error) return <div>{error}</div>;
          if (loading)
            return (
              <div className="container">
                <Loading />
              </div>
            );
          const { property } = data;

          return (
            <React.Fragment>
              <HeaderMeta
                seo={{
                  title: `${property.title} - Real Estate ${
                    property.suburb.suburbName
                  }, ${property.suburb.city}`,
                  description: property.description,
                  image: getImages(property.images[0].id, 600, 400),
                  url: `https://${this.props.url}/property/${mandateID}`
                }}
              />
              <Property property={property} />
            </React.Fragment>
          );
        }}
      </Query>
    );
  }
}
