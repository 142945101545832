import React from "react";
import { Space, Border, Text, spacing } from "@pc/shared";
import SelectBox from "./SearchDropdown";
import SelectCheckbox from "./SearchCheckbox";

export const SearchHeader = props => {
  return (
    <div className="container">
      <Space bottom size={spacing.space4}>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <Text inline display="small">
              {`${props.count} properties found`}
            </Text>
          </div>

          <div>
            <SelectBox
              placeholder={"Order by"}
              plural={"Property Types"}
              selected={props.search.onOrderBy}
              orderByBox={true}
            >
              <SelectCheckbox
                label={"Price High Low"}
                value={"PriceHighLow"}
                checked={props.search.orderBy === "PriceHighLow" ? true : false}
                onClick={props.onOrderBy}
              />
              <SelectCheckbox
                label={"Price Low High"}
                value={"PriceLowHigh"}
                checked={props.search.orderBy === "PriceLowHigh" ? true : false}
                onClick={props.onOrderBy}
              />
            </SelectBox>
          </div>
        </div>
        <Space margin bottom top size={spacing.space2}>
          <Border />
        </Space>
      </Space>
    </div>
  );
};
