import * as React from "react";
import { Space, Box, Button, ButtonText, Border, spacing } from "@pc/shared";
import SelectBox from "./SearchDropdown";
import SelectCheckbox from "./SearchCheckbox";
import { propertyTypes } from "../../../common/options";
import SelectIncrement from "./SearchIncrement";
import SearchRheostate from "./SearchRheostat";
import AutoComplete from "../AutoComplete";
import { formatCurrency } from "../../../common/format";

export const SearchBox = ({
  container,
  agents,
  search,
  onSearch,
  onRoomUpdate,
  onPropertyUpdate,
  onStateUpdate,
  onResetState,
  onLocationUpdate,
  searchPlaceholder,
  orderByBox,
  ...restProps
}) => {
  return (
    <Space padding bottom size={spacing.space2}>
      <div className={container ? "container" : null}>
        <Space padding top bottom size={spacing.space2}>
          {restProps.saleType === "buttons" ? (
            <Space bottom size={spacing.space2}>
              <div className="d-flex flex-column flex-md-row align-items-stretch">
                <span className="mr-md-3 mb-3 mb-md-0">
                  <Button
                    block
                    display="outline"
                    hover={search.mandateType === "Sale"}
                    onClick={() =>
                      onStateUpdate
                        ? onStateUpdate("mandateType", "Sale")
                        : null
                    }
                  >
                    Buy
                  </Button>
                </span>
                <span className="mr-md-3 mb-3 mb-md-0">
                  <Button
                    block
                    display="outline"
                    hover={search.mandateType === "Rental"}
                    onClick={() =>
                      onStateUpdate
                        ? onStateUpdate("mandateType", "Rental")
                        : null
                    }
                  >
                    Rent
                  </Button>
                </span>
              </div>
            </Space>
          ) : null}

          <Box boxShadow={false} border borderRadius backgroundColor="light">
            <Space padding top right bottom left size={spacing.space1}>
              <div className="d-flex justify-content-between align-items-center flex-column flex-md-row align-items-stretch align-md-items-center">
                <div className="d-flex justify-content-between align-items-center w-100">
                  <Space
                    top
                    right
                    bottom
                    left
                    size={spacing.space1}
                    className="d-none d-md-block"
                  >
                    <i className="icon icon-search-big icon-2x" />
                  </Space>
                  <AutoComplete
                    locations={search.locations}
                    onLocationUpdate={onLocationUpdate}
                    searchPlaceholder={searchPlaceholder}
                  />
                </div>
                {onSearch ? (
                  <Button display="secondary" onClick={onSearch}>
                    Search
                  </Button>
                ) : null}
              </div>
            </Space>
          </Box>
        </Space>
      </div>
      <div className="container-full">
        <Border />
      </div>
      {!agents ? (
        <React.Fragment>
          <div className={container ? "container" : null}>
            <Space padding top bottom size={spacing.space2}>
              <div
                className={
                  "d-flex justify-content-between flex-column flex-md-row align-items-md-center"
                }
              >
                <div className="d-flex justify-content-between flex-column flex-md-row">
                  <SelectBox
                    placeholder={"Property Type"}
                    plural={"Property Types"}
                    selected={search.types}
                  >
                    {propertyTypes.map((type, index) => (
                      <SelectCheckbox
                        key={index}
                        label={type.label}
                        value={type.value}
                        checked={search.types.includes(type.value)}
                        onClick={onPropertyUpdate}
                      />
                    ))}
                  </SelectBox>
                  <SelectBox
                    placeholder={"Rooms"}
                    renderLabel={() => {
                      const label = [];
                      if (search.bedrooms > 0) {
                        label.push(`${search.bedrooms} bedroom`);
                      }

                      if (search.bathrooms > 0) {
                        label.push(`${search.bathrooms} bathroom`);
                      }

                      if (search.garages > 0) {
                        label.push(`other`);
                      }

                      if (label.length) {
                        return label.join(", ");
                      }

                      return "Rooms";
                    }}
                  >
                    <SelectIncrement
                      label={"Bedrooms"}
                      value={search.bedrooms}
                      onDecrement={() => onRoomUpdate("bedrooms", -1)}
                      onIncrement={() => onRoomUpdate("bedrooms", 1)}
                    />
                    <SelectIncrement
                      label={"Bathrooms"}
                      value={search.bathrooms}
                      onDecrement={() => onRoomUpdate("bathrooms", -1)}
                      onIncrement={() => onRoomUpdate("bathrooms", 1)}
                    />
                    <SelectIncrement
                      label={"Garages"}
                      value={search.garages}
                      onDecrement={() => onRoomUpdate("garages", -1)}
                      onIncrement={() => onRoomUpdate("garages", 1)}
                    />
                  </SelectBox>
                  <SelectBox
                    placeholder={"Min / Max Price"}
                    selected={search.priceRange}
                    renderLabel={() => {
                      if (
                        search.priceRange[0] === 0 &&
                        search.priceRange[1] === 10000000
                      ) {
                        return "Min/Max Price";
                      }

                      return `${formatCurrency(
                        search.priceRange[0]
                      )} - ${formatCurrency(search.priceRange[1])}`;
                    }}
                  >
                    <SearchRheostate
                      min={0}
                      max={10000000}
                      values={search.priceRange}
                      onChange={v => onStateUpdate("priceRange", v.values)}
                      snap={true}
                      snapPoints={Array.from(Array(200).keys()).map(
                        r => r * 500000
                      )}
                    />
                  </SelectBox>
                </div>

                <ButtonText display="primary" onClick={onResetState}>
                  Clear All
                </ButtonText>
              </div>
            </Space>
          </div>{" "}
          <div className="container-full">
            <Border />
          </div>
        </React.Fragment>
      ) : null}
    </Space>
  );
};

SearchBox.defaultProps = {
  container: false,
  agents: false
};
