import gql from "graphql-tag";

export const MUTATION_SUBMIT_FORM = gql`
  mutation submitForm($input: InputFormDetails!) {
    submitForm(input: $input) {
      
        path
        message
      
    }
  }
`;
