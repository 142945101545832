import { Link } from "react-router-dom";
import * as React from "react";
import styled from "styled-components";
import { Space, Text, spacing, colors } from "@pc/shared";

export const StyledBreadcrumbs = styled.ul`
    margin: 0;
    padding: 0;
    display: inline;
    list-style: none;
    li {
      padding: 0;
      margin: 0;
      display: inline;
      color: ${colors.textDark};
      &:after {
        content: "/";
        margin-left: ${spacing.space05};
        margin-right: ${spacing.space05};
      }
      a {
        text-decoration: none;
        color: ${colors.textDark};
        &:hover {
          color: ${colors.textDark};
          text-decoration: underline;
        }
      }
    }
  `;

export const Breadcrumbs = ({ property }) => {
  let type = "Property for sale";

  if (property.mandateType === "rental") {
    type = "Property to let";
  }

  const { suburb } = property;

  return (
    <StyledBreadcrumbs>
      <Space inline margin right size={spacing.space05}>
        <li><Text inline display="small">
          {type}
        </Text></li>

        {suburb.breadcrumbs &&
          suburb.breadcrumbs.map(b => (
            <li>
              <Text inline display="small" as={Link} key={b.slug} to={`${b.slug}`}>
                {b.label}
              </Text>
            </li>
          ))}

      </Space>
    </StyledBreadcrumbs>
  );
};
