import React from "react";
import { Query } from "react-apollo";
import { RELATED_PROPERTIES_QUERY } from "./queries/properties";
import { Properties } from "./ui/Properties";
import { Loading } from "@pc/shared";

export class PropertiesContainer extends React.Component {
  render() {
    const { suburb = [], mandateId, agents = [] } = this.props;

    const vars = {
      suburbIds: [Number(suburb)],
      agentIds: agents,
      mandateID: Number(mandateId)
    };

    return (
      <Query query={RELATED_PROPERTIES_QUERY} variables={vars}>
        {({ loading, error, data }) => {
          if (error) return <div>{error}</div>;
          if (loading)
            return (
              <div>
                <Loading />
              </div>
            );
          const { relatedProperties } = data;

          const filteredProperties = relatedProperties.filter(
            p => p.mandateID !== mandateId
          );

          if (relatedProperties.length) {
            return (
              <Properties
                properties={filteredProperties}
                mandateId={mandateId}
              />
            );
          }

          return null;
        }}
      </Query>
    );
  }
}
