export const formatCurrency = price => {
  return new Intl.NumberFormat("en-ZA", {
    style: "currency",
    currency: "ZAR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).format(price);
};

export const convertBr = content => {
  return content.replace(/<br\s*[/]?>/gi, "\n");
};
