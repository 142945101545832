import gql from "graphql-tag";

export const AREAS_QUERY = gql`
  query areaAggregation {
    areaAggregation {
      city
      count
      suburbs {
        name
        id
        slug
      }
    }
  }
`;
