"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BSCheckComponent = exports.BSSelectComponent = exports.BSTextareaComponent = exports.BSInputComponent = void 0;

var _react = _interopRequireDefault(require("react"));

var _Space = _interopRequireDefault(require("../Space/Space"));

var _Text = require("../Text/Text");

var _variables = require("../../common/styles/variables");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

var BSInputComponent = function BSInputComponent(_ref) {
  var field = _ref.field,
      _ref$form = _ref.form,
      touched = _ref$form.touched,
      errors = _ref$form.errors,
      props = _objectWithoutProperties(_ref, ["field", "form"]);

  return _react.default.createElement("div", {
    className: "form-group"
  }, _react.default.createElement("label", {
    htmlFor: [field.name]
  }, _react.default.createElement(_Text.Text, {
    display: "small"
  }, props.label)), _react.default.createElement("input", _extends({
    className: "form-control 11",
    type: field.type,
    id: [field.name]
  }, field)), _react.default.createElement(_Space.default, {
    top: true,
    size: _variables.spacing.space1
  }, _react.default.createElement(_Text.Text, {
    display: "small"
  }, [props.description])), touched[field.name] && errors[field.name] && _react.default.createElement("div", {
    className: "invalid-feedback",
    style: {
      display: "block"
    }
  }, errors[field.name]));
};

exports.BSInputComponent = BSInputComponent;

var BSTextareaComponent = function BSTextareaComponent(_ref2) {
  var field = _ref2.field,
      _ref2$form = _ref2.form,
      touched = _ref2$form.touched,
      errors = _ref2$form.errors,
      props = _objectWithoutProperties(_ref2, ["field", "form"]);

  return _react.default.createElement("div", {
    className: "form-group"
  }, _react.default.createElement("label", {
    htmlFor: [field.name]
  }, _react.default.createElement(_Text.Text, {
    display: "small"
  }, props.label)), _react.default.createElement("textarea", _extends({
    className: "form-control 22",
    type: "text",
    id: field.name
  }, field, props, {
    rows: "3"
  })), _react.default.createElement(_Space.default, {
    top: true,
    size: _variables.spacing.space1
  }, _react.default.createElement(_Text.Text, {
    display: "small"
  }, props.description)), touched[field.name] && errors[field.name] && _react.default.createElement("div", {
    className: "invalid-feedback"
  }, errors[field.name]));
};

exports.BSTextareaComponent = BSTextareaComponent;

var BSSelectComponent = function BSSelectComponent(_ref3) {
  var field = _ref3.field,
      _ref3$form = _ref3.form,
      touched = _ref3$form.touched,
      errors = _ref3$form.errors,
      props = _objectWithoutProperties(_ref3, ["field", "form"]);

  return _react.default.createElement("div", {
    className: "form-group"
  }, _react.default.createElement("label", {
    htmlFor: [field.name]
  }, _react.default.createElement(_Text.Text, {
    display: "small"
  }, [props.label])), _react.default.createElement("select", _extends({
    className: "custom-select",
    id: [field.name]
  }, field, props), [props.children]), touched[field.name] && errors[field.name] && _react.default.createElement("div", {
    className: "invalid-feedback"
  }, errors[field.name]));
};

exports.BSSelectComponent = BSSelectComponent;

var BSCheckComponent = function BSCheckComponent(_ref4) {
  var field = _ref4.field,
      _ref4$form = _ref4.form,
      touched = _ref4$form.touched,
      errors = _ref4$form.errors,
      props = _objectWithoutProperties(_ref4, ["field", "form"]);

  return _react.default.createElement("div", null, _react.default.createElement("div", {
    className: "custom-control custom-checkbox custom-control-inline"
  }, _react.default.createElement("input", _extends({
    type: "checkbox",
    id: field.name,
    name: field.name,
    className: "custom-control-input"
  }, field, props)), _react.default.createElement("label", {
    htmlFor: [field.name],
    className: "custom-control-label"
  }, _react.default.createElement(_Text.Text, {
    display: "small"
  }, props.label))), touched[field.name] && errors[field.name] && _react.default.createElement("div", {
    className: "invalid-feedback"
  }, errors[field.name]));
};

exports.BSCheckComponent = BSCheckComponent;