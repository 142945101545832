"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _variables = require("../../common/styles/variables");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  border-radius: ", ";\n  background-color: ", ";\n  padding: ", " ", ";\n  color: ", ";\n  font-size: 12px;\n  vertical-align: super;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

/** Label Component for displaying a small piece of text */
var StyledLabel = _styledComponents.default.span(_templateObject(), _variables.spacing.space05, _variables.colors.brandSecondary, _variables.spacing.space05, _variables.spacing.space1, _variables.colors.textWhite);

function Label(props) {
  var children = props.children;
  return _react.default.createElement(StyledLabel, null, children);
}

Label.propTypes = {
  /** Sets the layout of the component */
  display: _propTypes.default.oneOf(["vertical", "horizontal"]).isRequired,

  /** Sets the size of the component */
  size: _propTypes.default.oneOf(["large", "small"]).isRequired,

  /** Contents of the Box */
  children: _propTypes.default.node,

  /** Title of the card */
  title: _propTypes.default.string,

  /** meta of the person profile */
  meta: _propTypes.default.string,

  /** Image URL for the profile */
  image: _propTypes.default.string,

  /** Render Prop to display Actions or Buttons related to profile */
  renderCta: _propTypes.default.object,

  /** Click action */
  onClick: _propTypes.default.func
};
Label.defaultProps = {
  display: "horizontal",
  size: "large",
  children: null,
  title: null,
  meta: null,
  image: null,
  renderCta: null,
  onClick: function onClick() {}
};
var _default = Label;
exports.default = _default;