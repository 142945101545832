import * as React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import {
  Space,
  Text,
  ButtonText,
  Border,
  ButtonAction,
  CardProfile,
  Button,
  Image,
  SliderWrapper,
  spacing,
  ContentModal,
  ButtonIcon
} from "@pc/shared";
import { Breadcrumbs } from "./Breadcrumbs";
import { PropertiesContainer } from "../../Properties/PropertiesContainer";
import { CalculatorsContainer } from "../../Calculators/CalculatorsContainer";
import { formatCurrency } from "../../../common/format";
import { ContentAgent } from "../../Agent/ui/ContentAgent";
import { icons } from "../../../common/content";
import PropertyOverview from "./PropertyOverview";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton
} from "react-share";

import {
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  LinkedinIcon,
  EmailIcon
} from "react-share";

import ShareBox from "../ui/ShareBox";
import { getImages } from "../../../common/options";

export class Property extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      nav1: null,
      nav2: null,
      toggleBond: false
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
  }

  render() {
    const { property } = this.props;

    let url = null;

    if (process.browser) {
      url = window.location.href;
    }

    return (
      <React.Fragment>
        <div className="container">
          <Space bottom top size={spacing.space4}>
            <div className="row">
              <div className="col-12">
                <Breadcrumbs property={property} />
              </div>
            </div>
            <Space bottom top size={spacing.space2}>
              <Border />
            </Space>
          </Space>

          <Space bottom size={spacing.space6}>
            <div className="row">
              <div className="col-12">
                <Text display="title" color="brand" weight="medium">
                  {property.title}
                </Text>
              </div>
            </div>
            <div className="row align-items-end">
              <div className="col-md-5">
                <div className="d-flex">
                  <div className="d-flex align-items-left">
                    <div className="d-flex flex-column">
                      <Text display="titleSmall" color="brand">
                        {formatCurrency(property.price)}
                      </Text>
                      <Space right size={spacing.space2}>
                        <Text display="meta" color="dark" weight="medium">
                          Web reference: {property.listingNumber}
                        </Text>
                      </Space>
                    </div>
                    <div className="d-flex">
                      {property.mandateType !== "Rental" ? (
                        <Space left right top bottom size={spacing.space05}>
                          <ButtonIcon
                            name="calculator"
                            onClick={() =>
                              this.setState({
                                toggleBond: true
                              })
                            }
                          />

                          <ContentModal
                            isOpen={this.state.toggleBond}
                            closeModal={() =>
                              this.setState({ toggleBond: false })
                            }
                            data={null}
                            render={() => (
                              <CalculatorsContainer
                                purchasePrice={property.price}
                              />
                            )}
                          />
                        </Space>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <div className="d-flex justify-content-md-end justify-content-start">
                  {["Bedroom", "Bathroom", "Garage"].map(key =>
                    property.counts && property.counts[key] ? (
                      <div key={key} className="ml-md-4 mr-md-0 mr-3">
                        <Text inline display="metaSmall" weight="normal">
                          <i className={`icon icon-${icons[key]}`} />{" "}
                          {property.counts[key]}{" "}
                        </Text>
                      </div>
                    ) : null
                  )}

                  {property.meta.floor ? (
                    <div className="ml-md-4 mr-md-0 mr-3">
                      <Text
                        inline
                        display="metaSmall"
                        color="dark"
                        weight="medium"
                      >
                        Floor Size:{" "}
                        <Text
                          inline
                          display="metaSmall"
                          color="dark"
                          weight="normal"
                        >
                          {property.meta.floor.Size}m<sup>2</sup>
                        </Text>
                      </Text>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </Space>

          <Space bottom size={spacing.space8}>
            <div className="row">
              <div className="col-12">
                <Space bottom size={spacing.space2}>
                  <Slider
                    arrows={true}
                    infinite={false}
                    slidesToShow={1}
                    adaptiveHeight={false}
                    asNavFor={this.state.nav2}
                    ref={slider => (this.slider1 = slider)}
                  >
                    {property.images.map((image, index) => {
                      return (
                        <div key={index}>
                          <Image
                            borderRadius={spacing.space1}
                            fluid={true}
                            src={getImages(image.id, 1200, 700)}
                          />
                        </div>
                      );
                    })}
                  </Slider>
                </Space>
                <Slider
                  infinite={false}
                  asNavFor={this.state.nav1}
                  ref={slider => (this.slider2 = slider)}
                  slidesToShow={6}
                  slidesToShowSmall={4}
                  fade={false}
                  focusOnSelect={true}
                >
                  {property.images.map((image, index) => {
                    return (
                      <Space
                        key={index}
                        padding
                        left
                        right
                        size={spacing.space1}
                      >
                        <Image
                          borderRadius={spacing.space1}
                          fluid={true}
                          src={getImages(image.id, 160, 90)}
                        />
                      </Space>
                    );
                  })}
                </Slider>
              </div>
            </div>
          </Space>

          <Space bottom size={spacing.space10}>
            <div className="row">
              <div className="col-lg-7">
                <Text
                  display="body"
                  as="p"
                  weight="light"
                  html={property.description}
                />
              </div>

              <div className="col-lg-5 col-xl-4 offset-xl-1">
                {property.agents.map((agent, index) => {
                  return (
                    <Space bottom size={spacing.space4} key={agent.agentId}>
                      <CardProfile
                        index={index}
                        image={
                          agent.image
                            ? getImages(agent.image.FileId, 120, 120)
                            : null
                        }
                        title={agent.fullName}
                        renderCta={
                          <React.Fragment>
                            <Button
                              display="outline"
                              size="small"
                              onClick={() =>
                                this.setState({ open: true, agent: agent })
                              }
                            >
                              Get in Touch
                            </Button>
                          </React.Fragment>
                        }
                      >
                        <ButtonText
                          as={"a"}
                          href={`mailto:${agent.email}`}
                          block
                          iconBefore="envelope"
                        >
                          <Text truncate html={agent.email} />
                        </ButtonText>
                        <ButtonText
                          as={"a"}
                          href={`tel:${agent.mobile}`}
                          block
                          iconBefore="phone"
                        >
                          <Text truncate html={agent.mobile} />
                        </ButtonText>
                      </CardProfile>
                    </Space>
                  );
                })}
                <ContentModal
                  isOpen={this.state.open}
                  closeModal={() => this.setState({ open: false, agent: null })}
                  data={this.state.agent}
                  render={agent => (
                    <ContentAgent
                      {...agent}
                      property={property}
                      onSubmit={this.props.onSubmitEnquiry}
                      form="enquire"
                    />
                  )}
                />
              </div>
            </div>
          </Space>

          <div className="row align-items-end justify-content-between">
            <div className="col-lg-6">
              <Space bottom size={spacing.space6}>
                <Text display="title" color="brand" weight="medium">
                  Property Details
                </Text>
              </Space>
            </div>

            <div className="col-lg-6">
              <div className="d-flex justify-content-start justify-content-md-start justify-content-lg-end">
                <Space bottom size={spacing.space4}>
                  <span className="ml-0 ml-lg-4 mr-4 mr-lg-0">
                    <ShareBox label="Share">
                      <div className="d-flex justify-content-center">
                        <FacebookShareButton
                          url={url}
                          quote={property.title}
                          className="btn"
                        >
                          <FacebookIcon size={32} round={true} />
                        </FacebookShareButton>
                        <TwitterShareButton
                          url={url}
                          title={property.title}
                          className="btn"
                        >
                          <TwitterIcon size={32} round={true} />
                        </TwitterShareButton>
                        <LinkedinShareButton
                          url={url}
                          title={property.title}
                          windowWidth={750}
                          windowHeight={600}
                          className="btn"
                        >
                          <LinkedinIcon size={32} round={true} />
                        </LinkedinShareButton>
                        <WhatsappShareButton
                          url={url}
                          title={property.title}
                          windowWidth={750}
                          windowHeight={600}
                          className="btn"
                        >
                          <WhatsappIcon size={32} round={true} />
                        </WhatsappShareButton>

                        <EmailShareButton
                          url={url}
                          subject={property.title}
                          body={
                            property.description
                              ? property.title +
                                "\n\n" +
                                property.description.replace(
                                  new RegExp("<br>", "g"),
                                  "\n"
                                ) +
                                "\n\n" +
                                url
                              : null
                          }
                          className="btn"
                        >
                          <EmailIcon size={32} round={true} />
                        </EmailShareButton>
                      </div>
                    </ShareBox>
                  </span>
                </Space>
              </div>
            </div>
          </div>
          <Space bottom size={spacing.space10}>
            <div className="row">
              <div className="col-12">
                <PropertyOverview id={property.mandateID} />
              </div>
            </div>
          </Space>

          <Space bottom size={spacing.space15}>
            <div className="row">
              <div className="col-12">
                <div className="row justify-content-between">
                  {property.previousProperty ? (
                    <div className="col-5 col-md-2 mr-auto">
                      <ButtonAction
                        as={Link}
                        to={`/property/${property.previousProperty.mandateID}`}
                        iconBefore="caret-left"
                      >
                        Previous
                      </ButtonAction>
                    </div>
                  ) : null}
                  {property.nextProperty ? (
                    <div className="col-5 col-md-2 ml-auto">
                      <ButtonAction
                        as={Link}
                        to={`/property/${property.nextProperty.mandateID}`}
                        iconAfter="caret-right"
                      >
                        Next
                      </ButtonAction>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </Space>
        </div>

        <PropertiesContainer
          suburb={property.suburb.id}
          mandateId={property.mandateID}
          agents={property.agents}
        />
      </React.Fragment>
    );
  }
}
