import * as React from "react";
import { Space, Text, InputNumber, spacing } from "@pc/shared";

class SelectIncrement extends React.PureComponent {
  render() {
    const { label, left, value, onIncrement, onDecrement } = this.props;

    let checkOptions = {};

    if (onIncrement) {
      checkOptions.onIncrement = () => onIncrement();
    }

    if (onDecrement) {
      checkOptions.onDecrement = () => onDecrement();
    }

    let check = <InputNumber {...checkOptions} value={value} />;

    return (
      <Space margin top bottom size={spacing.space1}>
        <div className="d-flex justify-content-between align-items-center">
          {left ? check : null}
          <Text inline display="small" weight="normal">
            {label}
          </Text>
          {!left ? check : null}
        </div>
      </Space>
    );
  }
}

SelectIncrement.defaultProps = {
  value: null,
  left: false,
  label: null,
  onClick: null,
  checked: false
};

export default SelectIncrement;
