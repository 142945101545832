import * as React from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Space,
  CardProperty,
  Image,
  ButtonText,
  SliderWrapper,
  Text,
  spacing
} from "@pc/shared";

import { formatCurrency } from "../../../common/format";
const propertyCard = p => {
  return (
    <Space
      padding
      top
      left
      right
      bottom
      size={spacing.space2}
      key={p.mandateID}
    >
      <CardProperty
        size="small"
        display="vertical"
        title={formatCurrency(p.price)}
        meta={`${p.suburb.city}, ${p.suburb.suburbName}`}
        renderVisual={
          <SliderWrapper pager pagerType="arrow">
            {p.images.map(image => (
              <div key={image.id}>
                <Image fluid src={image.imageServer} />
              </div>
            ))}
          </SliderWrapper>
        }
        renderCta={hover => (
          <ButtonText
            as={Link}
            to={`/property/${p.mandateID}`}
            hover={hover}
            inline
            display="secondary"
            iconAfter="angle-right"
          >
            View property
          </ButtonText>
        )}
      >
        {["Bedroom", "Bathroom", "Garage"].map(key =>
          p.counts && p.counts[key] ? (
            <Space inline right size={spacing.space2}>
              <Text inline display="metaSmall">
                <i className="icon icon-bed" /> {p.counts[key]}
              </Text>
            </Space>
          ) : null
        )}
      </CardProperty>
    </Space>
  );
};
export const Properties = ({ properties, mandateId }) => (
  <Box backgroundColor="light">
    <Space padding top bottom size={spacing.space15}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Space bottom size={spacing.space6}>
              <Text
                display="title"
                color="brand"
                align="center"
                weight="medium"
              >
                More properties
              </Text>
            </Space>
          </div>
        </div>

        {properties.length < 5 ? (
          <div className="row justify-content-center">
            {properties.map(p => (
              <div className="col-3">
                <div>{propertyCard(p)}</div>
              </div>
            ))}
          </div>
        ) : (
          <SliderWrapper
            slidesToShow={4}
            slidesToShowSmall={1}
            slidesToShowMedium={2}
            slidesToShowLarge={2}
            pager
            pagerType="round"
            fade={false}
          >
            {properties.map(p => (
              <React.Fragment>{propertyCard(p)}</React.Fragment>
            ))}
          </SliderWrapper>
        )}
      </div>
    </Space>
  </Box>
);
