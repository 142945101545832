import React from "react";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import { SearchResults } from "../Search/ui/SearchResults";
import { SuburbHeader } from "./ui/SuburbHeader";
import { SearchContainer } from "../Search/SearchContainer";
import HeaderContainer from "../Navigation/HeaderContainer";
import { HeaderMeta } from "../../shared/ui/Helmet";
const fragments = {
  topLevel: gql`
    fragment Area on Area {
      id
      ...AreaHeaderInfo
      properties {
        ...PropertySearchInfo
      }
    }
    ${SearchResults.fragments.fields}
    ${SuburbHeader.fragments.fields}
  `
};

class SuburbContainer extends React.Component {
  searchProperties = values => {
    this.props.history.push(`/search`, values);
  };

  render() {
    const { loading, type } = this.props;

    if (loading) return null;

    const {
      area: { properties, ...areaInfo }
    } = this.props;

    return (
      <React.Fragment>
        <HeaderContainer bgColor={"white"} />
        <HeaderMeta
          seo={{
            title: `Real Estate & Property for sale in ${areaInfo.name}`,
            description: areaInfo.description
          }}
        />
        <SearchResults
          loading={loading}
          properties={properties}
          renderHeader={() => (
            <SuburbHeader area={areaInfo}>
              <SearchContainer
                container={true}
                searchOnly={true}
                saleType="buttons"
                onSearch={this.searchProperties}
                initState={{
                  locations: [
                    {
                      label: areaInfo.name,
                      value: type !== "suburb" ? areaInfo.name : areaInfo.id,
                      type: type
                    }
                  ]
                }}
              />
            </SuburbHeader>
          )}
        />
      </React.Fragment>
    );
  }
}

export default graphql(
  gql`
    query SuburbRequest($province: String!, $city: String, $suburb: String) {
      area(province: $province, city: $city, suburb: $suburb) {
        ...Area
      }
    }
    ${fragments.topLevel}
  `,
  {
    options: props => {
      const {
        match: { params }
      } = props;
      return {
        variables: { ...params }
      };
    },
    props: ({ data }) => ({ ...data })
  }
)(SuburbContainer);
