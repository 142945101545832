"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PrimaryMenu = PrimaryMenu;

var React = _interopRequireWildcard(require("react"));

var _reactRouterDom = require("react-router-dom");

var _Text = require("../../Text/Text");

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _variables = require("../../../common/styles/variables");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {}; if (desc.get || desc.set) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } } newObj.default = obj; return newObj; } }

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  margin: 0;\n  padding: 0;\n  list-style: none;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  @media (min-width: 768px) {\n    flex-direction: row;\n  }\n  li {\n    margin: 0;\n    font-family: ", ";\n    font-size: 16px;\n    line-height: 1;\n    font-weight: ", ";\n    display: flex;\n    @media (min-width: 768px) {\n      margin-right: ", ";\n    }\n    a {\n      text-decoration: none;\n      padding-top: ", ";\n      padding-bottom: ", ";\n      :hover,\n      :focus,\n      :active {\n        color: ", ";\n        text-decoration: none;\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

function PrimaryMenu(props) {
  var items = props.items;
  return React.createElement(StyledPrimaryMenu, null, items.map(function (p) {
    return React.createElement("li", {
      key: p.label
    }, p.externalLink ? React.createElement("a", {
      href: p.url
    }, React.createElement(_Text.Text, {
      display: "titleTiny",
      weight: "medium",
      html: p.label
    })) : React.createElement(_Text.Text, {
      as: _reactRouterDom.Link,
      to: p.url,
      display: "titleTiny",
      weight: "medium",
      html: p.label
    }));
  }));
}

var StyledPrimaryMenu = _styledComponents.default.ul(_templateObject(), _variables.fonts.sans, _variables.fontWeight.medium, _variables.spacing.space3, _variables.spacing.space1, _variables.spacing.space1, _variables.colors.textBrand);