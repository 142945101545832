import React from "react";
import {
  Space,
  ContentModal,
  Text,
  spacing,
  Border,
  Box,
  CardProfile,
  Button,
  ButtonText
} from "@pc/shared";
import profile from "../../../common/images/profile.jpg";
import { Link } from "react-router-dom";
import { formatCurrency } from "../../../common/format";
import { ContentAgent } from "../../Agent/ui/ContentAgent";
import { SearchContainer } from "../../Search/SearchContainer";
import SelectBox from "../../Search/ui/SearchDropdown";
import SelectCheckbox from "../../Search/ui/SearchCheckbox";
import { getImages } from "../../../common/options";

const LinkCheck = link => {
  let url = link;
  if (!/^https?:\/\//i.test(url)) {
    url = "https://" + url;
  }

  return url;
};
export const Agents = props => {
  const { agents } = props;

  return (
    <React.Fragment>
      <Border />
      <SearchContainer
        onSearch={props.onSearch}
        container={true}
        searchOnly={true}
        agents={true}
        searchPlaceholder={"Enter suburb name"}
      />
      <div className="container">
        <Space margin bottom size={spacing.space4}>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <Text inline display="small">
                {`${agents.filter(a => a.active).length} agents found`}
              </Text>
            </div>

            <div style={{ width: "180px" }}>
              <div>
                <SelectBox
                  placeholder={"Order by"}
                  plural={"Agents"}
                  orderByBox={true}
                  // selected={props.search.onOrderBy}
                >
                  <SelectCheckbox
                    label={"A-Z"}
                    value={"ascending"}
                    checked={props.currentOrder === "ascending" ? true : false}
                    onClick={props.order}
                  />
                  <SelectCheckbox
                    label={"Z-A"}
                    value={"descending"}
                    checked={props.currentOrder === "descending" ? true : false}
                    onClick={props.order}
                  />
                </SelectBox>
              </div>
            </div>
          </div>
          <Space margin bottom top size={spacing.space2}>
            <Border />
          </Space>
        </Space>

        <div className="row align-items-stretch">
          {agents
            .filter(a => a.active)
            .map((agent, index) => {
              const { propertyCount } = agent;

              return (
                <div index={index} className="col-sm-6 col-md-4">
                  <Space margin bottom size={spacing.space4}>
                    <Box border borderRadius>
                      <Space
                        padding
                        top
                        right
                        bottom
                        left
                        size={spacing.space3}
                      >
                        <CardProfile
                          display="full"
                          image={
                            agent.image
                              ? getImages(agent.image.FileId, 120, 120)
                              : profile
                          }
                          title={agent.fullName}
                          role={agent.subtitle || "Property Consultant"}
                          renderCta={
                            <div class="d-flex flex-column flex-xl-row align-items-stretch">
                              <div className="mr-0 mr-xl-2 mb-2 mb-xl-0">
                                <Button
                                  block
                                  size="small"
                                  onClick={() =>
                                    props.onToggleModal(true, agent)
                                  }
                                >
                                  Contact details
                                </Button>
                              </div>

                              <Button
                                as={
                                  agent.website && agent.website.address
                                    ? "a"
                                    : Link
                                }
                                to={
                                  agent.website && agent.website.address
                                    ? LinkCheck(agent.website.address)
                                    : `/agents/${agent.agentId}`
                                }
                                href={
                                  agent.website && agent.website.address
                                    ? LinkCheck(agent.website.address)
                                    : `/agents/${agent.agentId}`
                                }
                                display="outline"
                                size="small"
                              >
                                Visit website
                              </Button>
                            </div>
                          }
                        >
                          {propertyCount > 0 ? (
                            <ButtonText block size="small" iconBefore="home">
                              {propertyCount > 1
                                ? `${propertyCount} Properties`
                                : `${propertyCount} Property`}
                            </ButtonText>
                          ) : null}

                          {agent.medianPrice && (
                            <ButtonText block size="small" iconBefore="money">
                              {formatCurrency(agent.medianPrice)} Median Price
                            </ButtonText>
                          )}

                          {agent.medianAdvertising && (
                            <ButtonText
                              block
                              size="small"
                              iconBefore="calendar"
                            >
                              {agent.medianAdvertising} Days Median Advertising
                            </ButtonText>
                          )}
                        </CardProfile>
                      </Space>
                    </Box>
                  </Space>
                </div>
              );
            })}
        </div>
      </div>
      <ContentModal
        isOpen={props.open}
        closeModal={() => props.onToggleModal(false, null)}
        data={props.selectedAgent}
        render={agent => <ContentAgent showAbout={true} {...agent} />}
      />
    </React.Fragment>
  );
};
