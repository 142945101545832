import React from "react";
import ReactStreetview from "react-streetview";
import gql from "graphql-tag";

const fragments = {
  fields: gql`
    fragment MapFields on MapBlock {
      lat
      lng
    }
  `
};

class MapBlock extends React.Component {
  state = {
    show: false
  };

  componentDidMount() {
    this.setState({
      show: true
    });
  }
  render() {
    if (!this.state.show) return null;
    // see https://developers.google.com/maps/documentation/javascript
    const googleMapsApiKey = process.env.RAZZLE_GOOGLE_MAPS_API;

    // see https://developers.google.com/maps/documentation/javascript/3.exp/reference#StreetViewPanoramaOptions
    const streetViewPanoramaOptions = {
      position: {
        lat: parseFloat(this.props.lat),
        lng: parseFloat(this.props.lng)
      },
      pov: { heading: 100, pitch: 0 },
      zoom: 1
    };

    return (
      <div
        style={{
          width: "100%",
          height: "450px",
          backgroundColor: "#eeeeee"
        }}
      >
        <ReactStreetview
          apiKey={googleMapsApiKey}
          streetViewPanoramaOptions={streetViewPanoramaOptions}
        />
      </div>
    );
  }
}

MapBlock.fragments = fragments;

export default MapBlock;
