"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LegalMenu = LegalMenu;

var React = _interopRequireWildcard(require("react"));

var _reactRouterDom = require("react-router-dom");

var _Text = require("../../Text/Text");

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _variables = require("../../../common/styles/variables");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {}; if (desc.get || desc.set) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } } newObj.default = obj; return newObj; } }

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  margin: 0;\n  padding: 0;\n  list-style: none;\n  li {\n    margin: 0;\n    margin-left: ", ";\n    margin-right: ", ";\n    @media (min-width: 768px) {\n      margin-left: ", ";\n    }\n    a {\n      text-decoration: none;\n      :hover,\n      :focus,\n      :active {\n        color: ", ";\n        text-decoration: underline;\n      }\n    }\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  padding-top: ", ";\n  margin-bottom: ", ";\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  border-top: 1px solid ", ";\n  @media (min-width: 768px) {\n    flex-direction: row;\n    justify-content: space-between;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

function LegalMenu(props) {
  var items = props.items;
  return React.createElement(StyledLegal, null, React.createElement("div", null, React.createElement(_Text.Text, {
    display: "small",
    color: "dark"
  }, "\xA9 ", new Date().getFullYear(), " Property Cloud SA (Pty) Ltd. All rights reserved.")));
}

var StyledLegal = _styledComponents.default.div(_templateObject(), _variables.spacing.space6, _variables.spacing.space6, _variables.colors.borderLight);

var StyledLegalMenu = _styledComponents.default.ul(_templateObject2(), _variables.spacing.space1, _variables.spacing.space1, _variables.spacing.space3, _variables.colors.textDark);