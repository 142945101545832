"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sizeStyles = exports.displayStyles = void 0;

var _variables = require("../../../../common/styles/variables");

var displayStyles = {
  primary: {
    bgColor: _variables.colors.btnPrimary,
    textColor: _variables.colors.textWhite,
    borderColor: _variables.colors.btnPrimary,
    // For CSS hover
    bgColorHover: _variables.colors.btnPrimaryHover,
    borderColorHover: _variables.colors.btnPrimaryHover,
    textColorHover: _variables.colors.textWhite,
    hover: {
      bgColor: _variables.colors.btnPrimaryHover,
      borderColor: _variables.colors.btnPrimaryHover,
      textColor: _variables.colors.textWhite
    }
  },
  secondary: {
    bgColor: _variables.colors.btnSecondary,
    textColor: _variables.colors.textWhite,
    borderColor: _variables.colors.btnSecondary,
    // For CSS hover
    bgColorHover: _variables.colors.btnSecondaryHover,
    borderColorHover: _variables.colors.btnSecondaryHover,
    textColorHover: _variables.colors.textWhite,
    hover: {
      bgColor: _variables.colors.btnSecondaryHover,
      borderColor: _variables.colors.btnSecondaryHover,
      textColor: _variables.colors.textWhite
    }
  },
  alternative: {
    bgColor: _variables.colors.btnAlternative,
    textColor: _variables.colors.brandPrimary,
    borderColor: _variables.colors.btnAlternative,
    // For CSS hover
    bgColorHover: _variables.colors.btnAlternativeHover,
    borderColorHover: _variables.colors.btnAlternativeHover,
    textColorHover: _variables.colors.textWhite,
    hover: {
      bgColor: _variables.colors.btnAlternativeHover,
      borderColor: _variables.colors.btnAlternativeHover,
      textColor: _variables.colors.textWhite
    }
  },
  outline: {
    bgColor: _variables.colors.bgWhite,
    textColor: _variables.colors.textDark,
    borderColor: _variables.colors.borderBase,
    // For CSS hover
    bgColorHover: _variables.colors.btnBrand,
    borderColorHover: _variables.colors.btnBrand,
    textColorHover: _variables.colors.textWhite,
    hover: {
      bgColor: _variables.colors.btnBrand,
      borderColor: _variables.colors.btnBrand,
      textColor: _variables.colors.textWhite
    }
  }
};
exports.displayStyles = displayStyles;
var sizeStyles = {
  medium: {
    sm: {
      padding: "11.5px 35px",
      fontSize: "14px",
      lineHeigt: "26px",
      fontWeight: _variables.fontWeight.medium
    },
    md: {
      padding: "11.5px 35px",
      fontSize: "17px",
      lineHeigt: "26px",
      fontWeight: _variables.fontWeight.medium
    }
  },
  small: {
    sm: {
      padding: "6.5px 26px",
      fontSize: "14px",
      lineHeigt: "26px",
      fontWeight: _variables.fontWeight.normal
    },
    md: {
      padding: "6.5px 26px",
      fontSize: "14px",
      lineHeigt: "26px",
      fontWeight: _variables.fontWeight.normal
    }
  }
};
exports.sizeStyles = sizeStyles;