import gql from "graphql-tag";

export const SEARCH_SUBURBS = gql`
  query searchSuburbs($keyword: String) {
    searchSuburbs(keyword: $keyword) {
      type
      value
      label
    }
  }
`;
